import React from 'react';
import { useSelector } from "react-redux";

// Utils
import { Translate } from "utils/translations/Translate";

// Styles
import '../StatisticsItem/StatisticsItem.scss';


const AboutGeoColumbiaItem = () => {
    const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('public/columbia', lang)
	const t = translation.getWord()

    return (
        <div className={`stats__cards`}>
            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Audience')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_co`}>
                        <div className={`stats__info_co_item`} >
                            <div className={`stats__info_co_title`}>{t('More than 35 million')}</div>
                            <div className={`stats__info_co_text`}>{t('More than 35 million active Internet users, while 56% of them use mobile devices, and 42% prefer desktop.')}</div>
                        </div>
                        <div className={`stats__info_co_item`} >
                            <div className={`stats__info_co_title`}>{t('$239')}</div>
                            <div className={`stats__info_co_text`}>{t(`$239 average salary in Colombia.`)}</div>
                        </div>
                        <div className={`stats__info_co_item`} >
                            <div className={`stats__info_co_title`}>{t('67%')}</div>
                            <div className={`stats__info_co_text`}>{t('67% of the total population is involved in the service sector.')}</div>
                        </div>
                        <div className={`stats__info_co_item`} >
                            <div className={`stats__info_co_title`}>{t('35.2%')}</div>
                            <div className={`stats__info_co_text`}>{t('35.2% of adults have at least one credit product.')}</div>
                        </div>
                        <div className={`stats__info_co_item`} >
                            <div className={`stats__info_co_title`}>{t('32 million')}</div>
                            <div className={`stats__info_co_text`}>{t('32 million Facebook users. Other popular sites include Youtube, Instagram, WhatsUp.')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutGeoColumbiaItem;