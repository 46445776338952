import React from 'react';

const SliderRegions = ({img, title, text}) => {
    return (
        <div className='landing__slider--block d-flex justify-content-between'>
            <div className='landing__slider--info'>
                <h2>{title}</h2>
                <p>{text}</p>
            </div>
            <div className='landing__slider--img'>
                <img src={img} alt="img" />
            </div>
        </div>
    );
}

export default SliderRegions;