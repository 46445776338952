import React from 'react';
import Select from 'react-select';

export default function CustomSelect({ options, name, className, handleChange, ...rest }) {
  const transformedOptions = options.map(option => ({ value: option, label: option }));
  const defaultValue = transformedOptions[0];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "url('../../../assets/images/general/bg-mini.png')",
      width: '100%',
      border: state.isFocused ? '1px solid #F8FFE4' : provided.border,
      boxShadow: state.isFocused ? 'none' : provided.boxShadow,
      borderRadius: '12px',
      fontSize: '1rem',
      lineHeight: '100%',
      padding: '0 1.25rem',
      color: '#F8FFE4',
      cursor: 'pointer',
      '&:hover': {
        
      }
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      padding: 0,
      margin: 0,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: 0,
      margin: 0,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      
    }),
  };

  const onChange = (selectedOption) => {
    const event = {
      target: {
        name: name,
        value: selectedOption ? selectedOption.value : ''
      }
    };
    handleChange(event);
  };

  return (
    <div className={`form__input ${className || ''}`}>
      <Select
        name={name}
        options={transformedOptions}
        defaultValue={defaultValue}
        onChange={onChange}
        className={className}
        isSearchable={false}
        styles={customStyles}
        {...rest}
      />
    </div>
  );
}
