import { lazy, Suspense } from 'react';
import {
	ADVERTISERS_PATH,
	ERROR_PAGE_404_PATH,
	FAQ_PATH,
	MAIN_PATH,
	LOGIN_PATH,
	OFFERS_PATH,
	RULES_PATH,
	PHILIPPINES_PATH,
	VIETNAM_PATH,
	UNITED_STATES_PATH,
	ROMANIA_PATH,
	SPAIN_PATH,
	MEXICO_PATH,
	POLAND_PATH,
	NIGERIA_PATH,
	COLUMBIA_PATH,
	CZECHIA_PATH,
	INDIA_PATH,
	KAZAKHSTAN_PATH,
	KENIA_PATH,
	SOUTH_AFRICA_PATH,
	SRI_LANKA_PATH,
	UKRAINE_PATH
} from "./path";
import { Loader } from "components/general";

const MainPage = lazy(() => import('pages/public/Main/Main'))
const Offers = lazy(() => import('pages/public/Offers/Offers'))
const Advertisers = lazy(() => import('pages/public/Advertisers/Advertisers'))
const FAQ = lazy(() => import('pages/public/FAQ/FAQ'))
const Rules = lazy(() => import('pages/public/Rules/Rules'))
const Philippines = lazy(() => import('pages/public/Philippines/Philippines'))
const Vietnam = lazy(() => import('pages/public/Vietnam/Vietnam'))
const UnitedStates = lazy(() => import('pages/public/UnitedStates/UnitedStates'))
const Romania = lazy(() => import('pages/public/Romania/Romania'))
const Spain = lazy(() => import('pages/public/Spain/Spain'))
const Mexico = lazy(() => import('pages/public/Mexico/Mexico'))
const Poland = lazy(() => import('pages/public/Poland/Poland'))
const Nigeria = lazy(() => import('pages/public/Nigeria/Nigeria'))
const Columbia = lazy(() => import('pages/public/Columbia/Columbia'))
const Czechia = lazy(() => import('pages/public/Czechia/Czechia'))
const India = lazy(() => import('pages/public/India/India'))
const Kazakhstan = lazy(() => import('pages/public/Kazakhstan/Kazakhstan'))
const Kenia = lazy(() => import('pages/public/Kenia/Kenia'))
const SouthAfrica = lazy(() => import('pages/public/SouthAfrica/SouthAfrica'))
const SriLanka = lazy(() => import('pages/public/SriLanka/SriLanka'))
const Ukraine = lazy(() => import('pages/public/Ukraine/Ukraine'))
const NotFound = lazy(() => import('pages/NotFound'))

const MAIN = {
	element: (
		<Suspense fallback={<Loader />}>
			<MainPage />
		</Suspense>
	),
	path: MAIN_PATH,
};

const LOGIN = {
	element: (
		<Suspense fallback={<Loader />}>
			<MainPage />
		</Suspense>
	),
	path: LOGIN_PATH,
};

const OFFERS = {
	element: (
		<Suspense fallback={<Loader />}>
			<Offers />
		</Suspense>
	),
	path: OFFERS_PATH,
};

const ADVERTISERS = {
	element: (
		<Suspense fallback={<Loader />}>
			<Advertisers />
		</Suspense>
	),
	path: ADVERTISERS_PATH,
};

const FAQ_PAGE = {
	element: (
		<Suspense fallback={<Loader />}>
			<FAQ />
		</Suspense>
	),
	path: FAQ_PATH,
};

const RULES = {
	element: (
		<Suspense fallback={<Loader />}>
			<Rules />
		</Suspense>
	),
	path: RULES_PATH,
};

const PHILIPPINES = {
	element: (
		<Suspense fallback={<Loader />}>
			<Philippines />
		</Suspense>
	),
	path: PHILIPPINES_PATH,
};

const VIETNAM = {
	element: (
		<Suspense fallback={<Loader />}>
			<Vietnam />
		</Suspense>
	),
	path: VIETNAM_PATH,
};
const UNITED_STATES = {
	element: (
		<Suspense fallback={<Loader />}>
			<UnitedStates />
		</Suspense>
	),
	path: UNITED_STATES_PATH,
};
const ROMANIA = {
	element: (
		<Suspense fallback={<Loader />}>
			<Romania />
		</Suspense>
	),
	path: ROMANIA_PATH,
};

const SPAIN = {
	element: (
		<Suspense fallback={<Loader />}>
			<Spain />
		</Suspense>
	),
	path: SPAIN_PATH,
};

const MEXICO = {
	element: (
		<Suspense fallback={<Loader />}>
			<Mexico />
		</Suspense>
	),
	path: MEXICO_PATH,
};

const POLAND = {
	element: (
		<Suspense fallback={<Loader />}>
			<Poland />
		</Suspense>
	),
	path: POLAND_PATH,
};

const NIGERIA = {
	element: (
		<Suspense fallback={<Loader />}>
			<Nigeria />
		</Suspense>
	),
	path: NIGERIA_PATH,
};

const COLUMBIA = {
	element: (
		<Suspense fallback={<Loader />}>
			<Columbia />
		</Suspense>
	),
	path: COLUMBIA_PATH,
};

const CZECHIA = {
	element: (
		<Suspense fallback={<Loader />}>
			<Czechia />
		</Suspense>
	),
	path: CZECHIA_PATH,
};

const INDIA = {
	element: (
		<Suspense fallback={<Loader />}>
			<India />
		</Suspense>
	),
	path: INDIA_PATH,
};

const KAZAKHSTAN = {
	element: (
		<Suspense fallback={<Loader />}>
			<Kazakhstan />
		</Suspense>
	),
	path: KAZAKHSTAN_PATH,
};

const KENIA = {
	element: (
		<Suspense fallback={<Loader />}>
			<Kenia />
		</Suspense>
	),
	path: KENIA_PATH,
};

const SOUTH_AFRICA = {
	element: (
		<Suspense fallback={<Loader />}>
			<SouthAfrica />
		</Suspense>
	),
	path: SOUTH_AFRICA_PATH,
};

const SRI_LANKA = {
	element: (
		<Suspense fallback={<Loader />}>
			<SriLanka />
		</Suspense>
	),
	path: SRI_LANKA_PATH,
};

const UKRAINE = {
	element: (
		<Suspense fallback={<Loader />}>
			<Ukraine />
		</Suspense>
	),
	path: UKRAINE_PATH,
};

const NOT_FOUND = {
	element: (
		<Suspense fallback={<Loader />}>
			<NotFound />
		</Suspense>
	),
	path: ERROR_PAGE_404_PATH,
};

const routes = [
	MAIN,
	LOGIN,
	OFFERS,
	ADVERTISERS,
	FAQ_PAGE,
	RULES,
	PHILIPPINES,
	VIETNAM,
	UNITED_STATES,
	ROMANIA,
	SPAIN,
	MEXICO,
	POLAND,
	NIGERIA,
	COLUMBIA,
	CZECHIA,
	INDIA,
	KAZAKHSTAN,
	KENIA,
	SOUTH_AFRICA,
	SRI_LANKA,
	UKRAINE,
	NOT_FOUND
];

export default routes;