import React from "react"
import ContentLoader from "react-content-loader"

const OfferCardSkeleton = (props) => (
	<ContentLoader
		speed={2}
		viewBox="0 0 400 337"
		backgroundColor="#979595"
		foregroundColor="#b8b7b7"
		style={{border: '1px solid #696969', borderRadius: '15px'}}
		{...props}
	>
		<rect x="32" y="32" rx="0" ry="0" width="334" height="40" />
		<rect x="32" y="96" rx="20" ry="20" width="177" height="36" />
		<rect x="218" y="96" rx="20" ry="20" width="72" height="36" />
		<rect x="226" y="178" rx="0" ry="0" width="140" height="52" />
		<rect x="32" y="178" rx="0" ry="0" width="140" height="52" />
		<rect x="32" y="154" rx="0" ry="0" width="334" height="1" />
		<rect x="34" y="252" rx="0" ry="0" width="335" height="1" />
		<rect x="32" y="275" rx="0" ry="0" width="127" height="30" />
	</ContentLoader>
)

export default OfferCardSkeleton