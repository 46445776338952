import React from 'react';

const RegionMap = ({ regionsData, hoveredGroup, setHoveredGroup }) => {
    return (
      <svg viewBox="0 0 240 286" fill="none" xmlns="http://www.w3.org/2000/svg">
        {regionsData.map((region, index) => (
          <path
            key={index}
            d={region.pathD}
            fill="#80FF00"
            fillOpacity={hoveredGroup === region.regionGroup ? '1' : '0.1'}
            onMouseEnter={() => setHoveredGroup(region.regionGroup)}
            onMouseLeave={() => setHoveredGroup(null)}
          />
        ))}
      </svg>
    );
  };
export default RegionMap;
