import React from 'react';
import { useSelector } from "react-redux";

// Utils
import { Translate } from "utils/translations/Translate";

// Styles
import '../StatisticsItem/StatisticsItem.scss';

// Images
import instagram from 'assets/images/public/about_geo_landing/instagram.svg';
import whatsapp from 'assets/images/public/about_geo_landing/whatsapp.svg';
import facebook from 'assets/images/public/about_geo_landing/facebook.svg';


const AboutGeoNigeriaItem = () => {
    const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('public/nigeria', lang)
	const t = translation.getWord()

    return (
        <div className={`stats__cards`}>
            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Audience')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_ng`}>
                        <div className={`stats__info_ng_item`} >
                            <div className={`stats__info_ng_title`}>{t('110 million')}</div>
                            <div className={`stats__info_ng_text`}>{t('110 million Nigerians use the Internet. About 33 million use social networks.')}</div>
                        </div>
                        <div className={`stats__info_ng_item`} >
                            <div className={`stats__info_ng_title`}>{t('24%')}</div>
                            <div className={`stats__info_ng_text`}>{t('24% of Nigerian adults use payday loans every year.')}</div>
                        </div>
                        <div className={`stats__info_ng_item`} >
                            <div className={`stats__info_ng_title`}>{t('70%')}</div>
                            <div className={`stats__info_ng_text`}>{t('70% of payday loans are issued to cover basic expenses such as rent and utility bills.')}</div>
                        </div>
                        <div className={`stats__info_ng_item`} >
                            <div className={`stats__info_ng_title`}>{t('500')}</div>
                            <div className={`stats__info_ng_text`}>{t('500 languages ​​are used by the Nigerian population in everyday life, but there is only one official language - English.')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`stats__item`}>
            <div className={`stats__heading`}>
                <div className={`stats__title`}>{t('Top social networks')}</div>
                <div className={`stats__lines`}></div>
            </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_traffic`}>
                        <div className={`stats__info_traffic_item`} >
                            <img src={whatsapp} alt='whatsapp' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Whatsapp')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={facebook} alt='facebook' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Facebook')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={instagram} alt='instagram' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Instagram')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutGeoNigeriaItem;