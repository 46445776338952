import React from 'react';
import { useSelector } from "react-redux";

// Utils
import { Translate } from "utils/translations/Translate";

// Styles
import '../StatisticsItem/StatisticsItem.scss';

// Images
import map_block from "assets/images/public/romania/statistic/map_block.png";
import ro_users_by_gender from "assets/images/public/romania/statistic/ro_users_by_gender.png";
import ro_users_by_os from "assets/images/public/romania/statistic/ro_users_by_os.png";


const AboutGeoRomaniaItem = () => {
    const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('public/romania', lang)
	const t = translation.getWord()

    return (
        <div className={`stats__cards`}>
            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Coverage by region')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__info`}>
                        <div className={`stats__info_img`}>
                            <img src={map_block} alt="map_block" />
                        </div>
                    </div>
                </div>
                <div className={`stats__info_descr`}>
                    <div className={`stats__info_descr_text`}>{t(`Most of`)} <span>{t(`PDL's`)}</span> {t(`clients are concentrated in big cities such as`)} <span>{t(`Bucharest, Cluj, Brasov, Constanta, Iași, Timisoara, Craiova and Galaţi.`)}</span></div>
                </div>
            </div>
            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Target customer')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content_diagrams`}>
                    <div className={`stats__block`}>
                        <div className={`stats__img`}>
                            <img src={ro_users_by_gender} alt={`ro_users_by_gender`} />
                        </div>
                        <div className={`stats__info`}>
                            <div className={`stats__info_item`}>
                                <div className={`stats__info_name`}>{t('Men')}</div>
                                <div className={`stats__info_count`}>55%</div>
                            </div>
                            <div className={`stats__info_item`}>
                                <div className={`stats__info_name`}>{t('Women')}</div>
                                <div className={`stats__info_count`}>45%</div>
                            </div>
                        </div>
                    </div>
                    <div className={`stats__block`}>
                        <div className={`stats__img`}>
                            <img src={ro_users_by_os} alt={`ro_users_by_gender`} />
                        </div>
                        <div className={`stats__info`}>
                            <div className={`stats__info_item`}>
                                <div className={`stats__info_name`}>{t('IOS')}</div>
                                <div className={`stats__info_count`}>16%</div>
                            </div>
                            <div className={`stats__info_item`}>
                                <div className={`stats__info_name`}>{t('Android')}</div>
                                <div className={`stats__info_count`}>84%</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`stats__info_descr`}>
                    <div className={`stats__info_descr_text`}>{t(`Clients aged`)} <span>{t(`20 to 35`)} </span> {t(`receive the highest approval`)}</div>
                </div>
            </div>
        </div>
    );
};

export default AboutGeoRomaniaItem;