import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { restorePassword, setRestoredEmail } from "redux/slices/auth/authSlice";

// Components
import { Input } from "components/general";
import Button from "components/general/Button/Button";

// Utils
import { Translate } from "utils/translations/Translate";
import { hideEmail } from "utils/hideEmail";

// Styles
import styles from "./RegLogModal.module.scss";

const ForgotPassword = ({ showRegLogModal }) => {
	const dispatch = useDispatch()
	const { restoredEmail, status, message, loading } = useSelector(state => state.auth)
	const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('public/auth', lang)
	const t = translation.getWord()

	const { register, formState: { errors }, handleSubmit } = useForm();

	const onSubmit = (data) => {
		const formData = new FormData()
		formData.append('email', data.email)

		dispatch(setRestoredEmail(data.email))
		dispatch(restorePassword(formData))
	};

	return (
		<div className={`${styles.modal__wrapper} ${styles.forgot__password}`}>
			<h3 className={styles.modal__title}>{t('Восстановление пароля')}</h3>
			<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
				{status && status === 'success' ? (
					<div className={styles.form__body}>
						<svg width="72" height="73" viewBox="0 0 72 73" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M60.1225 11.5C65.2102 11.5 69.333 15.4167 69.333 20.25V52.75C69.333 57.5833 65.2102 61.5 60.1225 61.5H11.8768C9.43406 61.5 7.09133 60.5781 5.36402 58.9372C3.63671 57.2962 2.66632 55.0706 2.66632 52.75V20.25C2.66632 15.4167 6.78913 11.5 11.8768 11.5H60.1225ZM64.947 27.085L37.0558 41.6583C36.7688 41.8082 36.4502 41.8952 36.1232 41.9128C35.7962 41.9304 35.4693 41.8782 35.1663 41.76L34.9435 41.66L7.05229 27.0833V52.75C7.05229 53.9656 7.56059 55.1314 8.46537 55.9909C9.37015 56.8504 10.5973 57.3333 11.8768 57.3333H60.1225C61.402 57.3333 62.6292 56.8504 63.5339 55.9909C64.4387 55.1314 64.947 53.9656 64.947 52.75V27.085ZM60.1225 15.6667H11.8768C10.5973 15.6667 9.37015 16.1496 8.46537 17.0091C7.56059 17.8686 7.05229 19.0344 7.05229 20.25V22.3317L35.9997 37.455L64.947 22.33V20.25C64.947 19.0344 64.4387 17.8686 63.5339 17.0091C62.6292 16.1496 61.402 15.6667 60.1225 15.6667Z"
								fill="#F8FFE4" />
						</svg>
						<p>
							{t('Мы отправили вам письмо-подтверждение на емейл')} {hideEmail(restoredEmail)}
						</p>
					</div>
				) : (
					<div className={styles.inputs__wrapper}>
						<Input
							name="email"
							placeholder={t('Введите ваш емейл')}
							register={register}
							errors={errors}
							required={{ required: true, pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i }}
						/>
					</div>
				)}

				<div className={styles.form__footer}>
					{status && status === 'error' && <p className='error mb-2'>{message}</p>}
					{(!status || status !== 'success') && <Button className={`outline-green ${loading ? 'disabled' : ''}`}>{t('Отправить письмо')}</Button>}
					<p className={`${styles.forgot__password} ${status === 'success' ? 'align-self-start' : ''}`}>
						{t('Вспомнили пароль?')}
						<span onClick={() => showRegLogModal('login')}>{t('Войти')}</span>
					</p>
				</div>
			</form>
		</div>
	);
};

export default ForgotPassword;