import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

const HOST = process.env.REACT_APP_HOST

export const openStatOffersApi = createApi({
    reducerPath: 'openStatOffersApi',
    baseQuery: fetchBaseQuery({baseUrl: HOST}),
    endpoints: (build) => ({
        getOpenStatOffers: build.query({
            query: ({page, region, search, orderBy, orderDir, lang = "ua"}) => {
                const params = new URLSearchParams({
                    lang,
                    page: String(page),
                    onRedirect: '0',
                    perPage: '10',
                    region: region.value || '',
                    search: search || '',
                    orderBy: orderBy || '',
                    orderDir: orderDir || ''
                });

                return `v1/openstat/offers?${params.toString()}`;
            }
        })
    })
})

export const {useGetOpenStatOffersQuery} = openStatOffersApi