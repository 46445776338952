import React from 'react';
import { useSelector } from "react-redux";
import Slider from 'react-slick';

// Components
import Button from "components/general/Button/Button";

// Utils
import { Translate } from "utils/translations/Translate";

function SliderEvents({ events, showRegLogModal }) {

    const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('public/main', lang)
	const t = translation.getWord()



    // Settings for the main slider
    const eventSettings = {
        centerMode: true,
        centerPadding: '150px',  
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        speed: 500,
        infinite: true,
        className: 'event-slider-main',
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              centerPadding: '0px',
            }
          },
        ],
      };
  
    // Settings for the nested image sliders
    const imageSettings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 7000,
      pauseOnHover: true,
      speed: 500,
      slidesToShow: 1,
      className: 'event-slider-image',
      slidesToScroll: 1,
      draggable: false,
      swipe: false
    };
  
    return (
      <Slider {...eventSettings}>
        {events.map((event, idx) => (
          <div key={idx}>
            <div className='event__wrapper'>

                
                <Slider {...imageSettings}>
                {event.images.map((image, index) => (
                    <div key={index}>
                    <img src={image} alt={`Slide ${index}`} />
                    </div>
                ))}
                </Slider>
                
                
                <div className="event__description">
                    <div className="event__location">
                        <span>{event.location}</span>
                        <span>{event.date}</span>
                    </div>
                    <div className="event__text">
                        <h2>{event.title}</h2>
                        <p>{event.description}</p>
                    </div>
                    <Button className='outline-green' onClick={() => showRegLogModal('registration')}>{t('Присоедениться к нам')}</Button>

                </div>
                
                
            </div>
          </div>
        ))}
      </Slider>
    );
  }
  

export default SliderEvents;