import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
	defaults
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler
);


defaults.font.family = "TTSupermolotNeue"
defaults.font.size = 10
defaults.font.weight = 500

ChartJS.defaults.color = '#F8FFE4'

export const options = {
	responsive: true,
	maintainAspectRatio: false,
	default: {
		font: {
			size: 20,
			family: "TTSupermolotNeue"
		}
	},
	elements: {
		point: {
			radius: 0.8
		}
	},
	plugins: {
		legend: {
			display: false,
		},
		points: false,
		datalabels: {
			display: false
		}
	},
	scales: {
		x: {
			display: false,
		},
		CR: {
			type: 'linear',
			display: true,
			position: 'left',
			grid: {
				display: true,
				color: '#2A2B30'
			},
			title: {
				display: true,
				text: 'CR',
				color: '#C7CABE',
			}
		},
		EPC: {
			type: 'linear',
			display: true,
			position: 'right',

			// grid line settings
			grid: {
				drawOnChartArea: false, // only want the grid lines for one axis to show up
				color: '#2A2B30',
				display: true,
			},
			title: {
				display: true,
				text: 'EPC',
				color: '#C7CABE',
			}
		}
	}
};


function OffersChart({ stats }) {
	const data = {
		labels: stats.map(item => item.date),
		datasets: [
			{
				yAxisID: 'CR',
				label: 'CR',
				data: stats.map(item => item.cr),
				borderColor: '#4D4E53',
				backgroundColor: '#4D4E53',
				tension: 0.3,
				fill: true,

			},
			{
				yAxisID: 'EPC',
				label: 'EPC',
				data: stats.map(item => item.ecpc),
				borderColor: '#4A594A',
				backgroundColor: '#4A594A',
				tension: 0.3,
				fill: true,
			},
		],
	};


	return <Line width={'100%'} options={options} data={data} />;
}

export default OffersChart