import { 
	MAIN_PATH,
	PHILIPPINES_PATH, 
	VIETNAM_PATH, 
	UNITED_STATES_PATH, 
	ROMANIA_PATH, 
	SPAIN_PATH, 
	MEXICO_PATH, 
	POLAND_PATH, 
	NIGERIA_PATH, 
	COLUMBIA_PATH, 
	CZECHIA_PATH, 
	INDIA_PATH, 
	KAZAKHSTAN_PATH, 
	KENIA_PATH, 
	SOUTH_AFRICA_PATH,
	SRI_LANKA_PATH,
	UKRAINE_PATH
} from "./path";
import { Loader } from "components/general";

const MAIN = {
	element: <Loader/>,
	path: MAIN_PATH,
};
const PHILIPPINES = {
	element: <Loader/>,
	path: PHILIPPINES_PATH,
};

const VIETNAM = {
	element: <Loader/>,
	path: VIETNAM_PATH,
};
const UNITED_STATES = {
	element: <Loader/>,
	path: UNITED_STATES_PATH,
};
const ROMANIA = {
	element: <Loader/>,
	path: ROMANIA_PATH,
};

const SPAIN = {
	element: <Loader/>,
	path: SPAIN_PATH,
};

const MEXICO = {
	element: <Loader/>,
	path: MEXICO_PATH,
};

const POLAND = {
	element: <Loader/>,
	path: POLAND_PATH,
};

const NIGERIA = {
	element: <Loader/>,
	path: NIGERIA_PATH,
};

const COLUMBIA = {
	element: <Loader/>,
	path: COLUMBIA_PATH,
};

const CZECHIA = {
	element: <Loader/>,
	path: CZECHIA_PATH,
};

const INDIA = {
	element: <Loader/>,
	path: INDIA_PATH,
};

const KAZAKHSTAN = {
	element: <Loader/>,
	path: KAZAKHSTAN_PATH,
};

const KENIA = {
	element: <Loader/>,
	path: KENIA_PATH,
};

const SOUTH_AFRICA = {
	element: <Loader/>,
	path: SOUTH_AFRICA_PATH,
};

const SRI_LANKA = {
	element: <Loader/>,
	path: SRI_LANKA_PATH,
};

const UKRAINE = {
	element: <Loader/>,
	path: UKRAINE_PATH,
};


const routes = [
	MAIN,
	PHILIPPINES,
	VIETNAM,
	UNITED_STATES,
	ROMANIA,
	SPAIN,
	MEXICO,
	POLAND,
	NIGERIA,
	COLUMBIA,
	CZECHIA,
	INDIA,
	KAZAKHSTAN,
	KENIA,
	SOUTH_AFRICA,
	SRI_LANKA,
	UKRAINE,
];

export default routes;