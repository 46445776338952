import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { registerOffer, resetStatus } from "redux/slices/auth/authSlice";

// Utils
import ReCAPTCHA from "react-google-recaptcha";
import { Translate } from "utils/translations/Translate";

// Components
import { Input, CustomSelect, Textarea, Button } from "components/general";

// Styles
import styles from './RegistrationForm.module.scss'

const messengers = {
	telegram: 'Contact',
	skype: 'Skype ID',
	whatsapp: 'Phone number with country code'
}

const SITE_KEY = process.env.REACT_APP_SITE_KEY

const RegistrationForm = () => {
	const dispatch = useDispatch()
	const [captcha, setCaptcha] = useState('')
	const [messenger, setMessenger] = useState(messengers["telegram"])
	const [submitted, setSubmitted] = useState(false)

	const {status, message, loading} = useSelector(state => state.auth)
	const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('public/auth', lang)
	const t = translation.getWord()

	const { register, formState: { errors }, handleSubmit, watch, setValue } = useForm();

	// change fields depend on messenger
	const handleChange = (e) => {
		setMessenger(messengers[e.target.value.toLowerCase()])
	}

	// validate captcha
	function handleOnChange(value) {
		setCaptcha(value)
	}

	const onSubmit = (data) => {
		if (!captcha) return
		const formData = new FormData()
		formData.append('name', data.name)
		formData.append('email', data.email)

		if (data.contact) {
			formData.append('contactType', data.contactType);
			formData.append('contact', data.contact);
		}
		formData.append('gc', captcha)

		if (data.website) formData.append('website', data.website)
		if (data.connectedCPA) formData.append('connectedCPA', data.connectedCPA)
		if (data.comment) formData.append('comment', data.comment)
		
		dispatch(registerOffer(formData))
		.then(() => {
			setSubmitted(true); 
		})
		.catch(() => {
			setSubmitted(false);
		});
	};

	useEffect(() => {
		// reset status when leave current component
		return () => {
			dispatch(resetStatus())
		}
	}, [dispatch])

	useEffect(() => {
		setValue("contactType", "Telegram");
	}, [setValue]);

	return (
		<>
			{submitted ? (
				<div className={styles.tnx}>
					<p>{t('Thank You, Your Feedback has been Submitted.')}</p> 
					<p>{t('We appreciate your input, and will follow up with you regarding your questions, concerns or comments as soon as we are able.')}</p>
					{t('Regards, Team')} <a style={{ }}  href="https://pdl-profit.com/">PDL-profit.com</a>.
				</div>
			) : (
			<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.inputs__wrapper}>
					<Input
						name="name"
						placeholder={t('Ваше имя и фамилия')}
						register={register}
						errors={errors}
						required={{ required: true, minLength: 3 }}
					/>

					<Input
						name="email"
						placeholder='Email'
						register={register}
						errors={errors}
						required={{ required: true, pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i }}
					/>


					<CustomSelect
						name="contactType"
						className="custom-select"
						handleChange={(e) => {
							handleChange(e);
							setValue("contactType", e.target.value);
						}}
						classNamePrefix="custom-select"
						options={["Telegram", "Skype", "WhatsApp"]}
					/>

					<Input
						name="contact"
						placeholder={messenger}
						register={register}
						errors={errors}
					/>

					<Input
						name="website"
						placeholder={t('Сайт')}
						register={register}
						errors={errors}
					/>

					<Input
						name="connectedCPA"
						placeholder={t('С какими CPA-сетями сотрудничаете?')}
						register={register}
						errors={errors}
					/>

					<Textarea
						name='comment'
						className='textarea'
						placeholder={t('Комментарий')}
						register={register}
						errors={errors}
					/>

					<Input
						name="conditions"
						type='checkbox'
						className='checkbox'
						label={<p className={"checkbox__label"}>
							{t('Вы подтверждаете что ознакомились и согласны со следующими')} <Link to='/rules.html' target='_blank' rel='noopener noreferrer'>{t('Условиями')}</Link>
						</p>}
						required={{ required: true }}
						register={register}
						errors={errors}
					/>
				</div>

				{status === 'success' && (
					<p className='success my-3'>Registration completed successfully</p>
				)}

				{status === 'error' && (
					<p className='error my-3'>{message}</p>
				)}

				<div className={styles.form__footer}>
					<ReCAPTCHA
						sitekey={SITE_KEY}
						onChange={handleOnChange}
					/>
					<Button className={`outline-green ${loading ? 'disabled' : ''}`}>{t('Зарегистрироваться')}</Button>
				</div>
			</form>
			)}
		</>

	);
};

export default RegistrationForm;