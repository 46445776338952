import Modal from "react-bootstrap/Modal";
import React from "react";

import styles from './ModalReviews.module.scss'


const ModalReviews = ({show, handleClose, name, position, link, text, photo}) => {

    return (
        <Modal size='xl' dialogClassName={styles.reviewsModal} centered show={show} onHide={handleClose}>
            <div className={styles.imgBlock}>
                <img src={photo} alt={name} />
            </div>
            <span onClick={handleClose} className='closeBtn'>
                <svg xmlns="http://www.w3.org/2000/svg" height="512px" version="1.1" viewBox="0 0 512 512"
                     width="512px"><path
                    d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z" /></svg>
            </span>
            <div className={styles.content}>
                <div className={`d-flex ${styles.content__title}`}>
                    <h2>{name}</h2>
                    <a target='_blank' rel="noreferrer" href={link}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <rect width="30" height="30" rx="15" fill="#4D4E53" />
                            <path
                                d="M16.2224 22.8723V15.7008H18.6418L19.0014 12.8929H16.2224V11.1044C16.2224 10.2942 16.4482 9.7394 17.611 9.7394H19.0845V7.23603C18.3676 7.1592 17.6469 7.1221 16.9259 7.1249C14.7874 7.1249 13.3192 8.4304 13.3192 10.827V12.8877H10.9155V15.6955H13.3244V22.8723H16.2224Z"
                                fill="#F8FFE4" />
                        </svg>
                    </a>
                </div>
                <div className={styles.content__position}>{position}</div>
                <p className={styles.content__text}>{text}</p>
            </div>
        </Modal>

    )


}


export default ModalReviews