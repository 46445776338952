import React from 'react';

const Input = ({
				   name,
				   register,
				   errors,
				   required,
				   label,
				   className,
				   placeholder,
				   type,
				   ...rest
			   }) => {
	const inputClasses = `form__input ${className || ''}`;

	return (
		<div className={inputClasses}>
			<label className={`${required ? 'input_req' : ''}`}>
				<input
					{...register(name, required)}
					placeholder={`${placeholder} ${required ? '' : ''}`}
					type={type}
					{...rest}
				/>
				{label}
				{type === 'checkbox' && <span className="checkmark" />}
			</label>
			{errors[name] && (
				<ErrorMessages
					error={errors[name]}
					minLength={required?.minLength}
				/>
			)}
		</div>
	);
};


const ErrorMessages = ({ error, minLength }) => {
	if (error.type === 'required') {
		return <p className="error">This field is required</p>;
	}

	if (error.type === 'validate') {
		return <p className="error">{error.message}</p>;
	}

	if (error.type === 'minLength') {
		return (
			<p className="error">
				This field must have at least {minLength} characters
			</p>
		);
	}

	if (error.type === 'pattern') {
		return <p className="error">Please enter a valid email address</p>;
	}

	return null;
};

export default Input;
