export const MAIN_PATH = '/';
export const LOGIN_PATH = '/login.html';
export const OFFERS_PATH = '/our-offers.html';
export const FAQ_PATH = '/faq.html';
export const RULES_PATH = '/rules.html';
export const ADVERTISERS_PATH = '/advertiser.html';
export const PHILIPPINES_PATH = '/ph';
export const VIETNAM_PATH = '/vn';
export const UNITED_STATES_PATH = '/us';
export const ROMANIA_PATH = '/ro';
export const SPAIN_PATH = '/es';
export const MEXICO_PATH = '/mx';
export const POLAND_PATH = '/pl';
export const NIGERIA_PATH = '/ng';
export const COLUMBIA_PATH = '/co';
export const CZECHIA_PATH = '/cz';
export const INDIA_PATH = '/in';
export const KAZAKHSTAN_PATH = '/kz';
export const KENIA_PATH = '/ke';
export const SOUTH_AFRICA_PATH = '/za';
export const SRI_LANKA_PATH = '/lk';
export const UKRAINE_PATH = '/ua';
export const ERROR_PAGE_404_PATH = '*';