import React from 'react';

import './StatisticsItem.scss'

const RegionListChart = ({ onHover, data, hoveredIndex }) => {
    return (
      <div className={`region__block`}>
        {data.map((region, index) => (
          <div
            key={region.region}
            onMouseEnter={() => onHover(index)}
            onMouseLeave={() => onHover(null)}
            className={`region__item ${index === hoveredIndex ? 'hovered-region' : ''}`}
          >
            <div className={`region__item__name`}>{region.region} </div>
            <div className={`region__item__count`}>{region.cr}%</div>
          </div>
        ))}
      </div>
    );
  };
  
  export default RegionListChart;
