import React from 'react';
import './StatisticsItem.scss'

const StatisticsItem = ({ data }) => {
 return (
    <div className={`stats__cards`}>
      {data.data.map((stat, i) => (
        <div className={`stats__item`} key={i}>
          <div className={`stats__heading`}>
              <div className={`stats__title`}>{stat.title}</div>
              <div className={`stats__lines`}></div>
          </div>
          <div className={`stats__content`}>
            <div className={`stats__img`}>
              <img src={stat.img} alt={`Diagram for ${stat.title}`} />
            </div>
            <div className={`stats__info`}>
              {stat.stats.map((item, index) => (
                <div className={`stats__info_item`} key={index}>
                 <div className={`stats__info_name`}>{item.name}</div>
                 <div className={`stats__info_count`}>{item.value}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
 );
};

export default StatisticsItem;