import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Translate } from "utils/translations/Translate";

// Components
import {
    RegionChart,
    RegionListChart
} from "components/public";

//Style
import '../StatisticsItem.scss'

// Images
import map_block from 'assets/images/public/kazakhstan/map_block.png';


const StatisticsKazakhstanItem = ({ data }) => {

  const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('public/kazakhstan', lang)
	const t = translation.getWord()

  const [hoveredIndex, setHoveredIndex] = useState(null);


  const dataRegionChart = [
    { region: t('Almaty'), cr: 5.28 },
    { region: t('Аstana'), cr: 6.28 },
    { region: t('Shymkent'), cr: 5.01 },
    { region: t('Karaganda'), cr: 5.66 },
    { region: t('Aktobe'), cr: 5.45 },
    { region: t('Ridder'), cr: 5.73 },
    { region: t('Oral'), cr: 4.9 },
    ];

  const chartData = {
    labels: dataRegionChart.map(d => d.cr),
    datasets: [
      {
        data: dataRegionChart.map(d => d.cr),
        backgroundColor: 'rgba(128, 255, 0, 0.1)',
        datalabels: {
          color: '#F8FFE4', 
          anchor: 'end',
          align: 'top',
          formatter: function(value) {
            return value + '%';
          }
        }
      }
    ],
  };

 return (
    <div className={`stats__cards`}>
        <div className={`stats__item`}>
            <div className={`stats__heading`}>
                <div className={`stats__title`}>{t('GEO DISTRIBUTION OF THE AUDIENCE')}</div>
                <div className={`stats__lines`}></div>
            </div>
            <div className={`stats__content`}>
                <div className={`stats__info`}>
                    <div className={`stats__info_img`}>
                        <img src={map_block} alt="map_block" />
                    </div>
                </div>
            </div>
            <div className={`stats__info_descr`}>
                <div className={`stats__info_descr_text`}>{t(`The majority of the`)} <span>{t(`PDL`)}</span> {t(`product audience is concentrated in the cities of`)} <span>{t(`Almaty, Astana, Shymkent, Karaganda, Aktobe, Ridder, Oral.`)}</span></div>
            </div>
        </div>
        <div className={`stats__item`}>
            <div className={`stats__heading`}>
                <div className={`stats__title`}>{t('CR by city')}</div>
                <div className={`stats__lines`}></div>
            </div>
            <div className={`stats__content`}>
                <div className={`chart`}>
                <div className={`chart-container`}>
                    <RegionChart
                    onHover={setHoveredIndex}
                    chartData={chartData}
                    hoveredIndex={hoveredIndex}
                    />
                </div>
                </div>


                <RegionListChart
                    onHover={setHoveredIndex}
                    data={dataRegionChart}
                    hoveredIndex={hoveredIndex}
                />
            </div>
        </div> 
        {data.data.map((stat, i) => (
            <div className={`stats__item`} key={i}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{stat.title}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__img`}>
                    <img src={stat.img} alt={`Diagram for ${stat.title}`} />
                    </div>
                    <div className={`stats__info`}>
                    {stat.stats.map((item, index) => (
                        <div className={`stats__info_item`} key={index}>
                        <div className={`stats__info_name`}>{item.name}</div>
                        <div className={`stats__info_count`}>{item.value}</div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        ))}               
    </div>
 );
};

export default StatisticsKazakhstanItem;