import React from 'react';
import arrowLeft from 'assets/images/general/arrow-left.svg'
import arrowRight from 'assets/images/general/arrow-right.svg'
import styles from './Pagination.module.scss'

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
	const maxPagesToShow = 5;
	const pages = [];

	if (totalPages <= maxPagesToShow) {
		for (let i = 1; i <= totalPages; i++) {
			pages.push(i);
		}
	} else {
		const pageDiff = Math.floor((maxPagesToShow - 3) / 2);
		let startPage = Math.max(currentPage - pageDiff, 2);
		let endPage = Math.min(currentPage + pageDiff, totalPages - 1);

		if (startPage === 2) {
			endPage = Math.min(startPage + maxPagesToShow - 3, totalPages - 1);
		} else if (endPage === totalPages - 1) {
			startPage = Math.max(endPage - maxPagesToShow + 3, 2);
		}

		pages.push(1);

		if (startPage > 2) {
			pages.push('ellipsis');
		}

		for (let i = startPage; i <= endPage; i++) {
			pages.push(i);
		}

		if (endPage < totalPages - 1) {
			pages.push('ellipsis');
		}

		pages.push(totalPages);
	}

	const handlePageChange = (e, page) => {
		e.preventDefault();
		onPageChange(page);
	};

	const handleEllipsisClick = (e, index) => {
		e.preventDefault();
		let newPage = currentPage;
		if (index === 1) {
			newPage = Math.max(currentPage - 3, 1);
		} else if (index === pages.length - 2) {
			newPage = Math.min(currentPage + 3, totalPages);
		}
		onPageChange(newPage);
	};

	return (
		<nav className={styles.pagination}>
			<ul className="list">
				<li className={`page-item${currentPage === 1 ? ' disabled' : ''}`}>
					<button className="page-link prev" onClick={(e) => handlePageChange(e, currentPage - 1)}>
						<img src={arrowLeft} alt="arrow-left" />
					</button>
				</li>
				{pages.map((page, index) => (
					<li key={index} className={`page-item${page === currentPage ? ' active' : ''}${page === 'ellipsis' ? ' ellipsis' : ''}`}>
						{page === 'ellipsis' ? (
							<button className="page-link" onClick={(e) => handleEllipsisClick(e, index)}>
								...
							</button>
						) : (
							<button className="page-link" onClick={(e) => handlePageChange(e, page)}>
								{page}
							</button>
						)}
					</li>
				))}
				<li className={`page-item${currentPage === totalPages ? ' disabled' : ''}`}>
					<button className="page-link next" onClick={(e) => handlePageChange(e, currentPage + 1)}>
						<img src={arrowRight} alt="arrow-right" />
					</button>
				</li>
			</ul>
		</nav>
	);
};


export default Pagination;
