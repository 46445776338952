import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Translate } from "utils/translations/Translate";

import image from 'assets/images/public/contacts_widget/contact-widget-image.png'
import styles from './ContactsWidget.module.scss'

const ContactsWidget = () => {
	const { lang } = useSelector(state => state.translations)
	const [show, setShow] = useState(false)

	// initialize translate class
	const translation = new Translate('contactsWidget', lang)
	const t = translation.getWord()

	const handleClickShow = () => {
		setShow(show => !show)
	}

	return (
		<div className={styles.contactsWidget}>
			<button className={styles.contactsWidget__btn} onClick={handleClickShow}>
				<img src={image} alt="widget_img" />
			</button>

			{show && (
				<ul className={styles.list}>
					<li>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M21.5625 3.12155C21.3813 2.96459 21.1608 2.85986 20.9246 2.81858C20.6885 2.77729 20.4456 2.80101 20.2218 2.88718L2.9156 9.68405C2.65006 9.78693 2.42518 9.97337 2.2749 10.2153C2.12461 10.4571 2.05707 10.7414 2.08247 11.025C2.10787 11.3086 2.22483 11.5763 2.41571 11.7876C2.60658 11.999 2.86101 12.1425 3.1406 12.1966L7.68747 13.1059V18.7497C7.68778 19.0083 7.76447 19.261 7.90791 19.4762C8.05135 19.6913 8.25515 19.8593 8.49372 19.9591C8.65459 20.0246 8.82628 20.0596 8.99997 20.0622C9.17249 20.0632 9.34347 20.0298 9.50287 19.9638C9.66226 19.8977 9.80684 19.8005 9.9281 19.6778L12.4875 17.1184L16.3125 20.4841C16.5494 20.6965 16.8568 20.8134 17.175 20.8122C17.3121 20.8133 17.4484 20.7911 17.5781 20.7466C17.7946 20.6777 17.9891 20.5531 18.1422 20.3853C18.2952 20.2174 18.4014 20.0122 18.45 19.7903L21.9844 4.40593C22.0372 4.17371 22.0258 3.9315 21.9515 3.70525C21.8772 3.47899 21.7427 3.27722 21.5625 3.12155ZM3.2156 10.9216C3.20904 10.882 3.21676 10.8415 3.23737 10.8071C3.25798 10.7728 3.29014 10.7469 3.3281 10.7341L17.8125 5.04343L8.11872 12.0466L3.3656 11.0903C3.32417 11.0857 3.28592 11.0659 3.25822 11.0347C3.23052 11.0036 3.21534 10.9632 3.2156 10.9216ZM9.13122 18.8809C9.10523 18.9082 9.07126 18.9265 9.0342 18.9332C8.99714 18.94 8.9589 18.9348 8.92497 18.9184C8.89201 18.9041 8.86389 18.8805 8.84394 18.8506C8.82399 18.8207 8.81307 18.7856 8.81247 18.7497V13.8841L11.6343 16.3778L9.13122 18.8809ZM17.3625 19.5372C17.3561 19.5697 17.3412 19.5999 17.3193 19.6248C17.2974 19.6497 17.2692 19.6683 17.2378 19.6788C17.2064 19.6893 17.1727 19.6912 17.1403 19.6845C17.1078 19.6777 17.0777 19.6625 17.0531 19.6403L9.14997 12.6841L20.8687 4.2278L17.3625 19.5372Z"
								fill="#F8FFE4" />
						</svg>
						<a rel="noreferrer" target='_blank' href="https://telegram.me/pdl_profit"
						   className={styles.list__link}>
						<span className={styles.list__title}>
							{t('Вебмастерам:')}
						</span>
							<div className={styles.list__name}>
								@pdl_profit
							</div>
						</a>
					</li>
					<li>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M21.5625 3.12155C21.3813 2.96459 21.1608 2.85986 20.9246 2.81858C20.6885 2.77729 20.4456 2.80101 20.2218 2.88718L2.9156 9.68405C2.65006 9.78693 2.42518 9.97337 2.2749 10.2153C2.12461 10.4571 2.05707 10.7414 2.08247 11.025C2.10787 11.3086 2.22483 11.5763 2.41571 11.7876C2.60658 11.999 2.86101 12.1425 3.1406 12.1966L7.68747 13.1059V18.7497C7.68778 19.0083 7.76447 19.261 7.90791 19.4762C8.05135 19.6913 8.25515 19.8593 8.49372 19.9591C8.65459 20.0246 8.82628 20.0596 8.99997 20.0622C9.17249 20.0632 9.34347 20.0298 9.50287 19.9638C9.66226 19.8977 9.80684 19.8005 9.9281 19.6778L12.4875 17.1184L16.3125 20.4841C16.5494 20.6965 16.8568 20.8134 17.175 20.8122C17.3121 20.8133 17.4484 20.7911 17.5781 20.7466C17.7946 20.6777 17.9891 20.5531 18.1422 20.3853C18.2952 20.2174 18.4014 20.0122 18.45 19.7903L21.9844 4.40593C22.0372 4.17371 22.0258 3.9315 21.9515 3.70525C21.8772 3.47899 21.7427 3.27722 21.5625 3.12155ZM3.2156 10.9216C3.20904 10.882 3.21676 10.8415 3.23737 10.8071C3.25798 10.7728 3.29014 10.7469 3.3281 10.7341L17.8125 5.04343L8.11872 12.0466L3.3656 11.0903C3.32417 11.0857 3.28592 11.0659 3.25822 11.0347C3.23052 11.0036 3.21534 10.9632 3.2156 10.9216ZM9.13122 18.8809C9.10523 18.9082 9.07126 18.9265 9.0342 18.9332C8.99714 18.94 8.9589 18.9348 8.92497 18.9184C8.89201 18.9041 8.86389 18.8805 8.84394 18.8506C8.82399 18.8207 8.81307 18.7856 8.81247 18.7497V13.8841L11.6343 16.3778L9.13122 18.8809ZM17.3625 19.5372C17.3561 19.5697 17.3412 19.5999 17.3193 19.6248C17.2974 19.6497 17.2692 19.6683 17.2378 19.6788C17.2064 19.6893 17.1727 19.6912 17.1403 19.6845C17.1078 19.6777 17.0777 19.6625 17.0531 19.6403L9.14997 12.6841L20.8687 4.2278L17.3625 19.5372Z"
								fill="#F8FFE4" />
						</svg>
						<a rel="noreferrer" target='_blank' href="https://t.me/pdl_profit_channel"
						   className={styles.list__link}>
						<span className={styles.list__title}>
							{t('Новости:')}
						</span>
							<div className={styles.list__name}>
								@pdl_profit_channel
							</div>
						</a>
					</li>
					<li>
						<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g opacity="0.9">
								<path
									d="M19.5417 4.7002C20.9917 4.7002 22.1667 5.8752 22.1667 7.3252V17.0752C22.1667 18.5252 20.9917 19.7002 19.5417 19.7002H5.79175C5.09555 19.7002 4.42788 19.4236 3.93559 18.9314C3.44331 18.4391 3.16675 17.7714 3.16675 17.0752V7.3252C3.16675 5.8752 4.34175 4.7002 5.79175 4.7002H19.5417ZM20.9167 9.3757L12.9677 13.7477C12.886 13.7927 12.7951 13.8187 12.702 13.824C12.6088 13.8293 12.5156 13.8136 12.4292 13.7782L12.3657 13.7482L4.41675 9.3752V17.0752C4.41675 17.4399 4.56161 17.7896 4.81948 18.0475C5.07734 18.3053 5.42708 18.4502 5.79175 18.4502H19.5417C19.9064 18.4502 20.2562 18.3053 20.514 18.0475C20.7719 17.7896 20.9167 17.4399 20.9167 17.0752V9.3757ZM19.5417 5.9502H5.79175C5.42708 5.9502 5.07734 6.09506 4.81948 6.35292C4.56161 6.61079 4.41675 6.96052 4.41675 7.3252V7.9497L12.6667 12.4867L20.9167 7.9492V7.3252C20.9167 6.96052 20.7719 6.61079 20.514 6.35292C20.2562 6.09506 19.9064 5.9502 19.5417 5.9502Z"
									fill="#F8FFE4" />
							</g>
						</svg>
						<a href="mailto:support@pdl-profit.com" className={styles.list__link}>
						<span className={styles.list__title}>
							E-mail
						</span>
							<div className={styles.list__name}>
								support@pdl-profit.com
							</div>
						</a>
					</li>
				</ul>
			)}

		</div>
	);
};

export default ContactsWidget;