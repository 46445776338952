import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { authApi } from "api/api";

const initialState = {
	throttlingLevel: '',
	loading: false,
	status: '',
	message: '',
	token: localStorage.getItem('token') || '',
	refresh: localStorage.getItem('refresh') || '',
	restoredEmail: ''
}

export const getThrottlingLevel = createAsyncThunk('auth/getThrottlingLevel', async (params, { rejectWithValue }) => {
	try {
		const { data } = await authApi.getThrottlingLevel()

		return data
	} catch (e) {
		return rejectWithValue(e)
	}
})

export const registerWebmaster = createAsyncThunk('auth/registerWebmaster', async (params, { rejectWithValue }) => {
	try {
		const { data } = await authApi.registrationWeb(params)

		return data
	} catch (e) {
		return rejectWithValue(e.response.data)
	}
})

export const registerOffer = createAsyncThunk('auth/registerOffer', async (params, { rejectWithValue }) => {
	try {
		const { data } = await authApi.registrationOffer(params)

		return data
	} catch (e) {
		return rejectWithValue(e.response.data)
	}
})

export const loginWebmaster = createAsyncThunk('auth/loginWebmaster', async (params, { rejectWithValue }) => {
	try {
		const { data } = await authApi.login(params)

		return data
	} catch (e) {
		return rejectWithValue(e.response.data)
	}
})

export const restorePassword = createAsyncThunk('auth/restorePassword', async (params, { rejectWithValue }) => {
	try {
		const { data } = await authApi.restorePassword(params)
		return data
	} catch (e) {
		return rejectWithValue(e.response.data)
	}
})


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		resetStatus(state) {
			state.status = ''
			state.message = ''
		},
		setToken(state, action) {
			state.token = action;
			localStorage.setItem('token', action);
		},
		setRefresh(state, action) {
			state.refresh = action;
			localStorage.setItem('refresh', action);
		},
		removeToken(state) {
			state.token = '';
			localStorage.removeItem('token');
		},
		removeRefresh(state) {
			state.refresh = '';
			localStorage.removeItem('refresh');
		},
		setRestoredEmail(state, action) {
			state.restoredEmail = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			// Check throttling level
			.addCase(getThrottlingLevel.pending, (state) => {
				state.loading = true
			})
			.addCase(getThrottlingLevel.fulfilled, (state, action) => {
				state.loading = false
				state.throttlingLevel = action.payload.level
			})
			.addCase(getThrottlingLevel.rejected, (state) => {
				state.loading = false
			})
			// Register Webmaster
			.addCase(registerWebmaster.pending, (state) => {
				state.loading = true
				state.status = ''
				state.message = ''
			})
			.addCase(registerWebmaster.fulfilled, (state, action) => {
				state.loading = false
				state.status = action.payload.status
			})
			.addCase(registerWebmaster.rejected, (state, action) => {
				state.loading = false
				state.status = action.payload.status
				state.message = action.payload.message
			})
			// Register Offer
			.addCase(registerOffer.pending, (state) => {
				state.loading = true
				state.status = ''
				state.message = ''
			})
			.addCase(registerOffer.fulfilled, (state, action) => {
				state.loading = false
				state.status = action.payload.status
			})
			.addCase(registerOffer.rejected, (state, action) => {
				state.loading = false
				state.status = action.payload.status
				state.message = action.payload.message
			})
			// Login Webmaster
			.addCase(loginWebmaster.pending, (state) => {
				state.loading = true
				state.status = ''
				state.message = ''
			})
			.addCase(loginWebmaster.fulfilled, (state, action) => {
				state.loading = false

				const token = action.payload.data.token
				const refresh = action.payload.data.refresh

				// Dispatch the setToken and setRefresh action
				authSlice.caseReducers.setToken(state, token)
				authSlice.caseReducers.setRefresh(state, refresh)
			})
			.addCase(loginWebmaster.rejected, (state, action) => {
				state.loading = false
				state.status = action.payload.status
				state.message = action.payload.message
				state.throttlingLevel = action.payload.data.throttling
			})
			// Restore Password
			.addCase(restorePassword.pending, (state) => {
				state.loading = true
				state.status = ''
				state.message = ''
			})
			.addCase(restorePassword.fulfilled, (state) => {
				state.loading = false
				state.status = 'success'

			})
			.addCase(restorePassword.rejected, (state, action) => {
				state.loading = false
				state.status = action.payload.status
				state.message = action.payload.message
			})
	}
})

export const checkIsAuth = (state) => Boolean(state.auth.token)

export const {
	resetStatus,
	setToken,
	setRefresh,
	removeToken,
	removeRefresh,
	setRestoredEmail
} = authSlice.actions