import {useEffect} from "react";
import {Route, Routes, useLocation, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {FooterFactory, HeaderFactory} from "./components/public";
import RegLogModal from "./components/public/RegLogModal/RegLogModal";
import {ModalProvider} from "./utils/customHooks/ModalContext";
import {checkIsAuth, getThrottlingLevel} from "./redux/slices/auth/authSlice";
import {setLang} from './redux/slices/translations/translationSlice' // Імпортуємо action для зміни мови
import privateRoutes from "./config/routing/privateRoutes";
import publicRoutes from "./config/routing/publicRoutes";

import './scss/app.scss'
import {clearParams, setTgAuth} from "./redux/slices/loginParams/loginParams";
import {selectLoginParams} from "./redux/selectors/loginParamsSelectors";
import MainLayout from "./layouts/Main/MainLayout";

const getDefaultLanguage = (queryLang, storedLang) => {
    if (queryLang) {
        return queryLang;
    } else if (storedLang) {
        return storedLang;
    } else {
        return 'en';
    }
};

function App() {
    const dispatch = useDispatch();
    const isAuth = useSelector(checkIsAuth);
    const loginParameters = useSelector(selectLoginParams);
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const tgAuth = searchParams.get('tgauth');
    const langParam = searchParams.get('lang');

    const landingPages = ['/ph', '/vn', '/us', '/ro', '/es', '/mx', '/pl', '/ng', '/co', '/cz', '/in', '/kz', '/ke', '/za', '/lk', '/ua'];
    const isLandingPage = () => landingPages.includes(location.pathname);

    const routes = isAuth ? privateRoutes : publicRoutes;

    useEffect(() => {
        if (!isAuth) {
            dispatch(getThrottlingLevel());
        }
    }, [dispatch, isAuth]);

    useEffect(() => {
        if (isAuth) {
            const token = localStorage.getItem('token');
            if (token) {
                // Replace 'adminPanelUrl' with the actual URL of your admin panel
                const adminPanelUrl = '/login.html';
                const urlParams = new URLSearchParams();
                urlParams.append('appToken', token);

                Object.keys(loginParameters).map(q => {
                    urlParams.append(q, loginParameters[q]);
                })

                const loginUrl = `${adminPanelUrl}?${urlParams.toString()}`;
                localStorage.removeItem('token');
                localStorage.removeItem('refresh');
                dispatch(clearParams());
                window.location.href = loginUrl;
            } else {
                console.error('Token not found in local storage');
            }
        }
    }, [isAuth]);

    useEffect(() => {
        const storedLang = localStorage.getItem('lang');
        const newLang = getDefaultLanguage(langParam, storedLang);
        if (!storedLang || (langParam && storedLang !== langParam)) {
            dispatch(setLang(newLang));
            localStorage.setItem('lang', newLang);
        }
    }, [location, dispatch, langParam]);

    useEffect(() => {
        if (tgAuth) {
            dispatch(setTgAuth(1));
        }
    }, [tgAuth]);

    return (
        <Routes>
            <Route element={<MainLayout isAuth={isAuth} isLandingPage={isLandingPage()}/>}>
                {routes.map((route) => (
                    <Route key={route.path} path={route.path} element={route.element}/>
                ))}
            </Route>
        </Routes>
    )
}

export default App;
