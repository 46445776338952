import React from 'react';

export default function Textarea({ register, errors, required, options, name, placeholder, className, ...rest }) {
	const inputClasses = `form__input ${className || ''}`;

	return (
		<div className={inputClasses}>
			<label>
			<textarea
				id={name}
				name={name}
				{...register(name, required)}
				aria-invalid={errors[name] ? "true" : "false"}
				placeholder={placeholder}
				{...rest}
			/>
				{errors[name]?.type === 'required' && (
					<p role="alert">This field is required</p>
				)}
				{errors[name]?.type === 'minLength' && (
					<p role="alert">This field must have at least {required.minLength} characters</p>
				)}
			</label>
		</div>
	);
}