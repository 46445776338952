import React, { createContext, useContext, useState } from "react";

// Create the modal context
const ModalContext = createContext();

// Custom hook to access the modal context
export const useModalContext = () => useContext(ModalContext);

// Modal context provider component
export const ModalProvider = ({ children }) => {
	const [modalType, setModalType] = useState("");
	const [show, setShow] = useState(false);

	const closeRegLogModal = () => {
		setShow(false);
	};

	const showRegLogModal = (type) => {
		if (type !== modalType) {
			setModalType(type);
		}
		setShow(true);
	};

	// Create the context value
	const modalContextValue = {
		modalType,
		show,
		closeRegLogModal,
		showRegLogModal,
	};

	return (
		<ModalContext.Provider value={modalContextValue}>
			{children}
		</ModalContext.Provider>
	);
};
