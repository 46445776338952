import React from 'react';
import Slider from "react-slick";
import { ReviewsCardLanding } from "../index";
import { useSelector } from "react-redux";
import { Translate } from "utils/translations/Translate";
import getReviewsLandingData from "../ReviewsCardLanding/reviewsLandingData";

import styles from './../ReviewsBlock/ReviewsBlock.module.scss'


const ReviewsBlockLanding = ({ setRev1, setRev2, rev1, rev2 }) => {
	const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('public/reviews', lang)
	const t = translation.getWord()
	const reviewsLandingData = getReviewsLandingData(lang);

	return (
		<section className="reviews">
			<div>
				<div className={`reviews__header_block d-flex flex-column flex-xl-row justify-content-between`}>
					<h1 className={`${styles.main__title}`}>{t('Reviews')}</h1>
					<h2 className={`${styles.main__subtitle}`}>
						{t('from publishers and advertisers')}
					</h2>
				</div>
				<div className="reviews__block d-flex flex-column flex-xl-row">
					<div className="reviews__row">
						<Slider className={'slick__reviews-1'}
								asNavFor={rev1}
								ref={(reviews2) => setRev2(reviews2)}
								slidesToShow={7}
								slidesToScroll={1}
								focusOnSelect={true}
								arrows={false}
								useTransform={false}
								responsive={[{
									breakpoint: 767,
									settings: {
										slidesToShow: 4,
										infinite: true
									}
								}]}
						>

							{reviewsLandingData.map((item, i) => (
								<img className='grayscale' key={i} src={item.photo} alt="person" />
							))}

						</Slider>
					</div>

					<div className="reviews__info">
						<Slider
							className='slick__reviews-2'
							asNavFor={rev2}
							ref={(reviews1) => setRev1(reviews1)}>

							{reviewsLandingData.map((item, i) => (
								<ReviewsCardLanding key={i} {...item} />
							))}

						</Slider>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ReviewsBlockLanding;