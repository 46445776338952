import React from 'react';
import { FooterPublic, FooterLanding } from "../index";



const FooterFactory = ({ isAuth, isLandingPage }) => {

    return isAuth ? <FooterPublic /> : isLandingPage ? <FooterLanding /> : <FooterPublic />;
	
};

export default FooterFactory;