import React from 'react';
import { useSelector } from "react-redux";

// Utils
import { Translate } from "utils/translations/Translate";

// Styles
import '../StatisticsItem/StatisticsItem.scss';

// Images
import instagram from 'assets/images/public/about_geo_landing/instagram.svg';
import whatsapp from 'assets/images/public/about_geo_landing/whatsapp.svg';
import facebook from 'assets/images/public/about_geo_landing/facebook.svg';
import tiktok from 'assets/images/public/about_geo_landing/tiktok.svg';
import twitter from 'assets/images/public/about_geo_landing/twitter.svg';
import ke_loan_purposes from 'assets/images/public/kenia/statistic/ke_loan_purposes.png'


const AboutGeoKeniaItem = () => {
    const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('public/kenia', lang)
	const t = translation.getWord()

    return (
        <div className={`stats__cards`}>
            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Audience')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_ke`}>
                        <div className={`stats__info_ke_item`} >
                            <div className={`stats__info_ke_title`}>{t('53 million')}</div>
                            <div className={`stats__info_ke_text`}>{t('53 million population, while there are only 24 million Internet users, 12 million are registered on social networks')}</div>
                        </div>
                        <div className={`stats__info_ke_item`} >
                            <div className={`stats__info_ke_title`}>{t('70%')}</div>
                            <div className={`stats__info_ke_text`}>{t(`70% of the country's population lives outside of large cities, which contributes to the development of microfinance organizations; banks operate only in large cities`)}</div>
                        </div>
                        <div className={`stats__info_ke_item`} >
                            <div className={`stats__info_ke_title`}>{t('$325')}</div>
                            <div className={`stats__info_ke_text`}>{t('$325 per month is the average income of a resident of Kenya, the average loan size is quite small – $15–20')}</div>
                        </div>
                        <div className={`stats__info_ke_item`} >
                            <div className={`stats__info_ke_title`}>{t('15%')}</div>
                            <div className={`stats__info_ke_text`}>{t('Only 15% of borrowers have at least some credit history')}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Loan purposes')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__img`}>
                    <img src={ke_loan_purposes} alt={`Diagram for loan purposes`} />
                    </div>
                    <div className={`stats__info`}>
                        <div className={`stats__info_item`}>
                            <div className={`stats__info_name`}>{t('maintaining your own micro-business')}</div>
                            <div className={`stats__info_count`}>40%</div>
                        </div>
                        <div className={`stats__info_item`}>
                            <div className={`stats__info_name`}>{t('for education')}</div>
                            <div className={`stats__info_count`}>20%</div>
                        </div>
                        <div className={`stats__info_item`}>
                            <div className={`stats__info_name`}>{t('loans for food, mobile communications, rent, household goods, etc.')}</div>
                            <div className={`stats__info_count`}>40%</div>
                        </div>
                    </div>
                </div>
            </div>


            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Top social media')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_traffic`}>
                        <div className={`stats__info_traffic_item`} >
                            <img src={whatsapp} alt='whatsapp' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('WhatsApp')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={facebook} alt='facebook' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Facebook')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={instagram} alt='instagram' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Instagram')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={tiktok} alt='tiktok' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Tiktok')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={twitter} alt='twitter' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Twitter (X)')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutGeoKeniaItem;