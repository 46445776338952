import React from 'react';
import { useSelector } from "react-redux";
import { Translate } from "utils/translations/Translate";
import Button from "components/general/Button/Button";


const ReviewsCard = ({name, link, position, shortText, allText, getData, photo}) => {
    const { lang } = useSelector(state => state.translations)

    // initialize translate class
    const translation = new Translate('public/reviews', lang)
    const t = translation.getWord()

    const btnHandler = () => {
        getData(name, position, link, allText, photo)
    }

    return <div className="reviews__card">
        <h3 className="reviews__name">
            {name}
            <a target='_blank' rel="noreferrer" href={link}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <rect width="30" height="30" rx="15" fill="#4D4E53" />
                    <path
                        d="M16.2224 22.8723V15.7008H18.6418L19.0014 12.8929H16.2224V11.1044C16.2224 10.2942 16.4482 9.7394 17.611 9.7394H19.0845V7.23603C18.3676 7.1592 17.6469 7.1221 16.9259 7.1249C14.7874 7.1249 13.3192 8.4304 13.3192 10.827V12.8877H10.9155V15.6955H13.3244V22.8723H16.2224Z"
                        fill="#F8FFE4" />
                </svg>
            </a>

        </h3>
        <div className="reviews__spec">
            {position}
        </div>
        <p className='reviews__text'>
            {shortText}
        </p>
        <Button onClick={btnHandler}>
            <svg width="32" height="2" viewBox="0 0 32 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.5" width="32" height="1" fill="#F8FFE4" />
            </svg>
            <span>
                {t('Читать полностью')}
            </span>
        </Button>
    </div>
}






export default ReviewsCard;