import React from 'react';
import { useSelector } from "react-redux";

// Utils
import { Translate } from "utils/translations/Translate";

// Styles
import '../StatisticsItem/StatisticsItem.scss';

// Images
import facebook from 'assets/images/public/about_geo_landing/facebook.svg';
import instagram from 'assets/images/public/about_geo_landing/instagram.svg';
import youtube from 'assets/images/public/about_geo_landing/youtube.svg';
import tiktok from 'assets/images/public/about_geo_landing/tiktok.svg';


const AboutGeoUkraineItem = () => {
    const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('public/ukraine', lang)
	const t = translation.getWord()

    return (
        <div className={`stats__cards`}>
            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Audience')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_ua`}>
                        <div className={`stats__info_ua_item`} >
                            <div className={`stats__info_ua_title`}>{t('41 million')}</div>
                            <div className={`stats__info_ua_text`}>{t('41 million people, more than 70% of them are Internet users.')}</div>
                        </div>
                        <div className={`stats__info_ua_item`} >
                            <div className={`stats__info_ua_title`}>{t('$450-500')}</div>
                            <div className={`stats__info_ua_text`}>{t(`$450-500 average salary in the country.`)}</div>
                        </div>
                        <div className={`stats__info_ua_item`} >
                            <div className={`stats__info_ua_title`}>{t('3 884 875')}</div>
                            <div className={`stats__info_ua_text`}>{t('3 884 875 payday loans worth $472 million were issued in the first half of 2023.')}</div>
                        </div>
                        <div className={`stats__info_ua_item`} >
                            <div className={`stats__info_ua_title`}>{t('650 000')}</div>
                            <div className={`stats__info_ua_text`}>{t('650 000 payday loans are issued per month.')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Top social media users')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_traffic`}>
                        <div className={`stats__info_traffic_item`} >
                            <img src={youtube} alt='youtube' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Youtube')}</div>
                            <div className={`stats__info_traffic_descr`}>{t('23 million users')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={facebook} alt='facebook' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Facebook')}</div>
                            <div className={`stats__info_traffic_descr`}>{t('15.6 million users')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={instagram} alt='instagram' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Instagram')}</div>
                            <div className={`stats__info_traffic_descr`}>{t('13.2 million users')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={tiktok} alt='tiktok' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Tiktok')}</div>
                            <div className={`stats__info_traffic_descr`}>{t('12 million users')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutGeoUkraineItem;