import React from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Translate } from "utils/translations/Translate";


import styles from './FooterPublic.module.scss'
import logo from 'assets/images/general/logo-pdl.svg'


function FooterPublic() {
	const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('footerFactory', lang)
	const t = translation.getWord()

	return (
		<>
		<footer className={styles.footer}>
			<div className="container">
				<div className={styles.footer__wrapper}>
					<div className={styles.info}>
						<div className={styles.info__logo}>
							<img src={logo} alt="logo" />
						</div>
						<h3 className={styles.info__title}>
							{t('Международная CPA сеть')}
							<br />
							{t('в финансовой вертикали PDL-profit')}
						</h3>
					</div>
					<ul className={styles.menu}>
						<li className={styles.menu__item}>
							<Link onClick={()=>{
								setTimeout(() => {
									const element = document.getElementById("whyUs");
									const position = element.getBoundingClientRect();
									document.documentElement.scrollTo({
										top:  position.top + document.documentElement.scrollTop - 120,
										left: position.left,
										behavior: "smooth", // Optional if you want to skip the scrolling animation
									});
								}, 100)
							}} to='/#whyUs'>{t('О нас')}</Link>
						</li>
						<li className={styles.menu__item}>
							<Link onClick={()=>{
								setTimeout(() => {
									const element = document.getElementById("individualAdvantages");
									const position = element.getBoundingClientRect();

									document.documentElement.scrollTo({
										top:  position.top + document.documentElement.scrollTop - 120,
										left: position.left,
										behavior: "smooth", // Optional if you want to skip the scrolling animation
									});
								}, 100)
							}} to='/#individualAdvantages'>{t('Вебмастеру')}</Link>
						</li>
						<li className={styles.menu__item}>
							<Link to='/advertiser.html'>{t('Рекламодателю')}</Link>
						</li>
						<li className={styles.menu__item}>
							<Link to='/our-offers.html'>{t('Офферы')}</Link>
						</li>
						<li className={styles.menu__item}>
							<Link to='/rules.html'>{t('Правила')}</Link>
						</li>
						<li className={styles.menu__item}>
							<Link to='/faq.html'>FAQ</Link>
						</li>
					</ul>
					<ul className={styles.social}>
						<li className={styles.social__item}>
							<svg width="25" height="25" viewBox="0 0 25 25" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<g opacity="0.9">
									<path
										d="M22.2292 3.34299C22.048 3.18603 21.8275 3.0813 21.5914 3.04001C21.3552 2.99873 21.1123 3.02244 20.8886 3.10861L3.58231 9.90549C3.31677 10.0084 3.0919 10.1948 2.94161 10.4367C2.79133 10.6786 2.72379 10.9628 2.74919 11.2464C2.77459 11.5301 2.89155 11.7977 3.08242 12.0091C3.27329 12.2204 3.52772 12.3639 3.80731 12.418L8.35419 13.3274V18.9711C8.3545 19.2297 8.43118 19.4824 8.57462 19.6976C8.71806 19.9128 8.92186 20.0808 9.16044 20.1805C9.32131 20.246 9.493 20.281 9.66669 20.2836C9.83921 20.2847 10.0102 20.2512 10.1696 20.1852C10.329 20.1192 10.4736 20.022 10.5948 19.8992L13.1542 17.3399L16.9792 20.7055C17.2161 20.9179 17.5235 21.0349 17.8417 21.0336C17.9788 21.0348 18.1151 21.0126 18.2448 20.968C18.4613 20.8992 18.6559 20.7746 18.8089 20.6067C18.962 20.4388 19.0681 20.2336 19.1167 20.0117L22.6511 4.62736C22.7039 4.39515 22.6925 4.15294 22.6182 3.92668C22.5439 3.70043 22.4094 3.49866 22.2292 3.34299ZM3.88231 11.143C3.87576 11.1035 3.88348 11.0629 3.90409 11.0286C3.9247 10.9942 3.95686 10.9683 3.99481 10.9555L18.4792 5.26486L8.78544 12.268L4.03231 11.3117C3.99088 11.3071 3.95263 11.2873 3.92494 11.2561C3.89724 11.225 3.88206 11.1847 3.88231 11.143ZM9.79794 19.1024C9.77194 19.1296 9.73797 19.1479 9.70092 19.1547C9.66386 19.1614 9.62562 19.1562 9.59169 19.1399C9.55873 19.1255 9.5306 19.1019 9.51065 19.072C9.49071 19.0421 9.47979 19.0071 9.47919 18.9711V14.1055L12.3011 16.5992L9.79794 19.1024ZM18.0292 19.7586C18.0228 19.7911 18.0079 19.8214 17.986 19.8463C17.9641 19.8711 17.936 19.8898 17.9045 19.9002C17.8731 19.9107 17.8394 19.9127 17.807 19.9059C17.7745 19.8992 17.7445 19.8839 17.7198 19.8617L9.81669 12.9055L21.5354 4.44924L18.0292 19.7586Z"
										fill="#F8FFE4" />
								</g>
							</svg>
							<span>Telegram support:</span>
							<a rel="noreferrer" target='_blank' href="https://telegram.me/pdl_profit">@pdl_profit</a>
						</li>
						<li className={styles.social__item}>
							<svg width="25" height="25" viewBox="0 0 25 25" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<g opacity="0.9">
									<path
										d="M22.2292 3.34299C22.048 3.18603 21.8275 3.0813 21.5914 3.04001C21.3552 2.99873 21.1123 3.02244 20.8886 3.10861L3.58231 9.90549C3.31677 10.0084 3.0919 10.1948 2.94161 10.4367C2.79133 10.6786 2.72379 10.9628 2.74919 11.2464C2.77459 11.5301 2.89155 11.7977 3.08242 12.0091C3.27329 12.2204 3.52772 12.3639 3.80731 12.418L8.35419 13.3274V18.9711C8.3545 19.2297 8.43118 19.4824 8.57462 19.6976C8.71806 19.9128 8.92186 20.0808 9.16044 20.1805C9.32131 20.246 9.493 20.281 9.66669 20.2836C9.83921 20.2847 10.0102 20.2512 10.1696 20.1852C10.329 20.1192 10.4736 20.022 10.5948 19.8992L13.1542 17.3399L16.9792 20.7055C17.2161 20.9179 17.5235 21.0349 17.8417 21.0336C17.9788 21.0348 18.1151 21.0126 18.2448 20.968C18.4613 20.8992 18.6559 20.7746 18.8089 20.6067C18.962 20.4388 19.0681 20.2336 19.1167 20.0117L22.6511 4.62736C22.7039 4.39515 22.6925 4.15294 22.6182 3.92668C22.5439 3.70043 22.4094 3.49866 22.2292 3.34299ZM3.88231 11.143C3.87576 11.1035 3.88348 11.0629 3.90409 11.0286C3.9247 10.9942 3.95686 10.9683 3.99481 10.9555L18.4792 5.26486L8.78544 12.268L4.03231 11.3117C3.99088 11.3071 3.95263 11.2873 3.92494 11.2561C3.89724 11.225 3.88206 11.1847 3.88231 11.143ZM9.79794 19.1024C9.77194 19.1296 9.73797 19.1479 9.70092 19.1547C9.66386 19.1614 9.62562 19.1562 9.59169 19.1399C9.55873 19.1255 9.5306 19.1019 9.51065 19.072C9.49071 19.0421 9.47979 19.0071 9.47919 18.9711V14.1055L12.3011 16.5992L9.79794 19.1024ZM18.0292 19.7586C18.0228 19.7911 18.0079 19.8214 17.986 19.8463C17.9641 19.8711 17.936 19.8898 17.9045 19.9002C17.8731 19.9107 17.8394 19.9127 17.807 19.9059C17.7745 19.8992 17.7445 19.8839 17.7198 19.8617L9.81669 12.9055L21.5354 4.44924L18.0292 19.7586Z"
										fill="#F8FFE4" />
								</g>
							</svg>
							<span>Telegram news:</span>
							<a rel="noreferrer" target='_blank'
							   href="https://t.me/pdl_profit_channel">@pdl_profit_channel</a>
						</li>
						<li className={styles.social__item}>
							<svg width="25" height="25" viewBox="0 0 25 25" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<g opacity="0.9">
									<path
										d="M19.5417 4.7002C20.9917 4.7002 22.1667 5.8752 22.1667 7.3252V17.0752C22.1667 18.5252 20.9917 19.7002 19.5417 19.7002H5.79175C5.09555 19.7002 4.42788 19.4236 3.93559 18.9314C3.44331 18.4391 3.16675 17.7714 3.16675 17.0752V7.3252C3.16675 5.8752 4.34175 4.7002 5.79175 4.7002H19.5417ZM20.9167 9.3757L12.9677 13.7477C12.886 13.7927 12.7951 13.8187 12.702 13.824C12.6088 13.8293 12.5156 13.8136 12.4292 13.7782L12.3657 13.7482L4.41675 9.3752V17.0752C4.41675 17.4399 4.56161 17.7896 4.81948 18.0475C5.07734 18.3053 5.42708 18.4502 5.79175 18.4502H19.5417C19.9064 18.4502 20.2562 18.3053 20.514 18.0475C20.7719 17.7896 20.9167 17.4399 20.9167 17.0752V9.3757ZM19.5417 5.9502H5.79175C5.42708 5.9502 5.07734 6.09506 4.81948 6.35292C4.56161 6.61079 4.41675 6.96052 4.41675 7.3252V7.9497L12.6667 12.4867L20.9167 7.9492V7.3252C20.9167 6.96052 20.7719 6.61079 20.514 6.35292C20.2562 6.09506 19.9064 5.9502 19.5417 5.9502Z"
										fill="#F8FFE4" />
								</g>
							</svg>
							<span>E-mail:</span>
							<a href="mailto:support@pdl-profit.com">support@pdl-profit.com</a>
						</li>
						<li className={styles.social__item}>
							<svg width="25" height="25" viewBox="0 0 25 25" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<path
									d="M18.1667 6.7002H18.1767M7.66675 2.2002H17.6667C20.4282 2.2002 22.6667 4.43877 22.6667 7.2002V17.2002C22.6667 19.9616 20.4282 22.2002 17.6667 22.2002H7.66675C4.90532 22.2002 2.66675 19.9616 2.66675 17.2002V7.2002C2.66675 4.43877 4.90532 2.2002 7.66675 2.2002ZM16.6667 11.5702C16.7902 12.4024 16.648 13.2524 16.2605 13.9992C15.873 14.746 15.2599 15.3516 14.5084 15.7299C13.7569 16.1081 12.9052 16.2398 12.0745 16.1061C11.2439 15.9725 10.4765 15.5803 9.88159 14.9854C9.28667 14.3904 8.89448 13.6231 8.76082 12.7924C8.62715 11.9617 8.75881 11.1101 9.13707 10.3586C9.51534 9.60705 10.1209 8.99394 10.8677 8.60644C11.6145 8.21894 12.4645 8.07678 13.2967 8.2002C14.1457 8.32608 14.9316 8.72166 15.5384 9.3285C16.1453 9.93534 16.5409 10.7213 16.6667 11.5702Z"
									stroke="#F8FFE4" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
							<span>Instagram:</span>
							<a rel="noreferrer" target='_blank'
							   href="https://www.instagram.com/pdl_profit/">instagram.com/pdl_profit</a>
						</li>
						<li className={styles.social__item}>
							<svg width="24" height="25" viewBox="0 0 24 25" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<path
									d="M18 2.2002H15C13.6739 2.2002 12.4021 2.72698 11.4645 3.66466C10.5268 4.60234 10 5.87411 10 7.2002V10.2002H7V14.2002H10V22.2002H14V14.2002H17L18 10.2002H14V7.2002C14 6.93498 14.1054 6.68063 14.2929 6.49309C14.4804 6.30555 14.7348 6.2002 15 6.2002H18V2.2002Z"
									stroke="#F8FFE4" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
							<span>Facebook:</span>
							<a rel="noreferrer" target='_blank'
							   href="https://www.facebook.com/pdl_profit/">facebook.com/pdl_profit</a>
						</li>
					</ul>
				</div>
			</div>
		</footer>
		</>
	);
}

export default FooterPublic;