import axios from 'axios'

const instance = (url, contentType = "application/json") => {
	return axios.create({
		headers: {
			"Content-Type": contentType,
			"Accept": "/",
		},
		baseURL: url,
	})
}

export default instance