import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {Translate} from "utils/translations/Translate";

// Components
import {
    RegionChart,
    RegionMap,
    RegionListMap,
    RegionListChart
} from "components/public";

//Style
import '../StatisticsItem.scss'

import getRegionsData from './regionsData';

const StatisticsSpainItem = ({data}) => {

    const {lang} = useSelector(state => state.translations)

    // initialize translate class
    const translation = new Translate('public/spain', lang)
    const t = translation.getWord();

    const regionsData = getRegionsData(lang);

    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [hoveredGroup, setHoveredGroup] = useState(null);


    const dataRegionChart = [
        {region: t('Catalonia'), cr: 1.78},
        {region: t('Galicia'), cr: 1.05},
        {region: t('Basque country'), cr: 2.03},
        {region: t('Aragon'), cr: 1.97},
        {region: t('Castile & Leon'), cr: 1.12},
        {region: t('Murcia & Valencia'), cr: 2.13},
        {region: t('Andalusia'), cr: 1.57},
        {region: t('Extremadura'), cr: 2.50},
        {region: t('Madrid'), cr: 1.50},
        {region: t('Castile La Mancha'), cr: 2.04},
        {region: t('Asturias & Cantabria'), cr: 1.56},
        {region: t('La Rioja & Navarra'), cr: 1.64},
        {region: t('Melilla & Ceuta'), cr: 4.76},
        {region: t('Canary Islands'), cr: 1.17},
        {region: t('Balearic Islands'), cr: 1.20},
    ];

    const chartData = {
        labels: dataRegionChart.map(d => d.cr),
        datasets: [
            {
                data: dataRegionChart.map(d => d.cr),
                backgroundColor: 'rgba(128, 255, 0, 0.1)',
                datalabels: {
                    color: '#F8FFE4',
                    anchor: 'end',
                    align: 'top',
                    formatter: function (value) {
                        return value + '%';
                    }
                }
            }
        ],
    };

    return (
        <div className={`stats__cards`}>
            {data.data.map((stat, i) => (
                <div className={`stats__item`} key={i}>
                    <div className={`stats__heading`}>
                        <div className={`stats__title`}>{stat.title}</div>
                        <div className={`stats__lines`}></div>
                    </div>
                    <div className={`stats__content`}>
                        <div className={`stats__img`}>
                            <img src={stat.img} alt={`Diagram for ${stat.title}`}/>
                        </div>
                        <div className={`stats__info`}>
                            {stat.stats.map((item, index) => (
                                <div className={`stats__info_item`} key={index}>
                                    <div className={`stats__info_name`}>{item.name}</div>
                                    <div className={`stats__info_count`}>{item.value}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Coverage by regions')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <RegionMap
                        regionsData={regionsData}
                        hoveredGroup={hoveredGroup}
                        setHoveredGroup={setHoveredGroup}
                    />
                    <RegionListMap
                        regionsData={regionsData}
                        hoveredGroup={hoveredGroup}
                        setHoveredGroup={setHoveredGroup}
                    />
                </div>
            </div>
            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Conversion Rate by regions')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`chart`}>
                        <div className={`chart-container`}>
                            <RegionChart
                                onHover={setHoveredIndex}
                                chartData={chartData}
                                hoveredIndex={hoveredIndex}
                            />
                        </div>
                    </div>


                    <RegionListChart
                        onHover={setHoveredIndex}
                        data={dataRegionChart}
                        hoveredIndex={hoveredIndex}
                    />
                </div>
            </div>
        </div>
    );
};

export default StatisticsSpainItem;