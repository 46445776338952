import React from 'react';
import { useSelector } from "react-redux";

// Utils
import { Translate } from "utils/translations/Translate";

// Styles
import '../StatisticsItem/StatisticsItem.scss';

// Images
import tiktok from 'assets/images/public/about_geo_landing/tiktok.svg';
import instagram from 'assets/images/public/about_geo_landing/instagram.svg';
import facebook from 'assets/images/public/about_geo_landing/facebook.svg';
import whatsapp from 'assets/images/public/about_geo_landing/whatsapp.svg';
import pinterest from 'assets/images/public/about_geo_landing/pinterest.svg';
import fbmessenger from 'assets/images/public/about_geo_landing/fbmessenger.svg';


const AboutGeoCzechiaItem = () => {
    const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('public/czechia', lang)
	const t = translation.getWord()

    return (
        <div className={`stats__cards`}>
            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Audience')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_cz`}>
                        <div className={`stats__info_cz_item`} >
                            <div className={`stats__info_cz_title`}>{t('91.6%')}</div>
                            <div className={`stats__info_cz_text`}>{t('91.6% of the population uses the Internet (about 9.6 million people); Facebook’s advertising target audience is about 4.2 million Czechs.')}</div>
                        </div>
                        <div className={`stats__info_cz_item`} >
                            <div className={`stats__info_cz_title`}>{t('$1,903')}</div>
                            <div className={`stats__info_cz_text`}>{t(`The average salary in the country is $1,903.`)}</div>
                        </div>
                        <div className={`stats__info_cz_item`} >
                            <div className={`stats__info_cz_title`}>{t('2.7%')}</div>
                            <div className={`stats__info_cz_text`}>{t('The 2.7% unemployment rate is one of the lowest in Europe.')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Top social media')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_traffic`}>
                        <div className={`stats__info_traffic_item`} >
                            <img src={facebook} alt='facebook' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Facebook')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={fbmessenger} alt='fbmessenger' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('FB Messenger')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={whatsapp} alt='whatsapp' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('WhatsApp')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={instagram} alt='instagram' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Instagram')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={tiktok} alt='tiktok' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('TikTok')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={pinterest} alt='pinterest' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Pinterest')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutGeoCzechiaItem;