import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const HOST = process.env.REACT_APP_HOST

export const profitPhilippinesOffersApi = createApi({
	reducerPath: 'profitPhilippinesOffersApi',
	baseQuery: fetchBaseQuery({ baseUrl: HOST }),
	endpoints: (build) => ({
		getProfitPhilippinesOffers: build.query({
			query: (lang) => `v1/openstat/offers?lang=${lang}&region=PH&page=1&perPage=30&onRedirect=0&showOnMain=1`
		})
	})
})

export const { useGetProfitPhilippinesOffersQuery } = profitPhilippinesOffersApi