import React from 'react';
import { useSelector } from "react-redux";

// Utils
import { Translate } from "utils/translations/Translate";

// Styles
import '../StatisticsItem/StatisticsItem.scss';

// Images
import tiktok from 'assets/images/public/about_geo_landing/tiktok.svg';
import instagram from 'assets/images/public/about_geo_landing/instagram.svg';
import twitter from 'assets/images/public/about_geo_landing/twitter.svg';
import facebook from 'assets/images/public/about_geo_landing/facebook.svg';
import seo from 'assets/images/public/about_geo_landing/SEO.svg';
import ppc from 'assets/images/public/about_geo_landing/PPC.svg';
import email from 'assets/images/public/about_geo_landing/email.svg';


const AboutGeoUsItem = () => {
    const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('public/unitedStates', lang)
	const t = translation.getWord()

    return (
        <div className={`stats__cards`}>
            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Audience')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_us`}>
                        <div className={`stats__info_us_item`} >
                            <div className={`stats__info_us_title`}>12 000 000</div>
                            <div className={`stats__info_us_text`}>{t('citizens annually apply for payday loans in the USA.')}</div>
                        </div>
                        <div className={`stats__info_us_item`} >
                            <div className={`stats__info_us_title`}>31.0%</div>
                            <div className={`stats__info_us_text`}>{t('of the global payday loans market comes from North America.')}</div>
                        </div>
                        <div className={`stats__info_us_item`} >
                            <div className={`stats__info_us_title`}>2 {t('weeks')}</div>
                            <div className={`stats__info_us_text`}>{t('is the average payday loan term.')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Target customer')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_us_benefits`}>
                        <div className={`stats__info_us_benefits_item`}>
                            <div className={`stats__info_us_benefits_title`}>26-40 <span>{t('years')}</span></div>
                            <div className={`stats__info_us_benefits_text`}>{t('average age')}</div>
                        </div>
                        <div className={`stats__info_us_benefits_item`}>
                            <div className={`stats__info_us_benefits_title`}><span>{t('more than')}</span> 59%</div>
                            <div className={`stats__info_us_benefits_text`}>{t('are single')}</div>
                        </div>
                        <div className={`stats__info_us_benefits_item`}>
                            <div className={`stats__info_us_benefits_title`}>1000$</div>
                            <div className={`stats__info_us_benefits_text`}>{t('minimum income')}</div>
                        </div>
                    </div>
                </div>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('The main reasons for lending')}</div>
                    <div className={`stats__lines small`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_us_reasons`}>
                        <div className={`stats__info_us_reasons_item`}>
                            <div className={`stats__info_us_reasons_count`}>01</div>
                            <div className={`stats__info_us_reasons_text`}>{t('Big purchases')}</div>
                        </div>
                        <div className={`stats__info_us_reasons_item`}>
                            <div className={`stats__info_us_reasons_count`}>02</div>
                            <div className={`stats__info_us_reasons_text`}>{t('Repair')}</div>
                        </div>
                        <div className={`stats__info_us_reasons_item`}>
                            <div className={`stats__info_us_reasons_count`}>03</div>
                            <div className={`stats__info_us_reasons_text`}>{t('Small business development')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`stats__item`}>
            <div className={`stats__heading`}>
                <div className={`stats__title`}>{t('Top traffic sources')}</div>
                <div className={`stats__lines`}></div>
            </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_traffic`}>
                        <div className={`stats__info_traffic_item`} >
                            <img src={tiktok} alt='tiktok' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('TikTok')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={twitter} alt='twitter' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Twitter')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={instagram} alt='instagram' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Instagram')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={facebook} alt='facebook' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Facebook')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={seo} alt='seo' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('SEO')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={ppc} alt='ppc' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('PPC')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={email} alt='email' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Email')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutGeoUsItem;