// hide email
// ex: test@test.com => te**@test.com
export function hideEmail(email) {
	const parts = email.split("@");
	const username = parts[0];
	const domain = parts[1];
	let hiddenUsername

	if (username.length < 5) {
		hiddenUsername = username.slice(0, 2) + "*".repeat(Math.max(username.length - 2, 0));
	} else {
		hiddenUsername = username.slice(0, 3) + "*".repeat(Math.max(username.length - 3, 0));
	}

	return hiddenUsername + "@" + domain;
}