const RegionListMap = ({ regionsData, hoveredGroup, setHoveredGroup }) => {
    const renderedGroups = new Set(); 
  
    return (
      <div className="region__block">
        {regionsData.map((region, index) => {
          if (renderedGroups.has(region.regionGroup)) {
            return null;
          }
  
          renderedGroups.add(region.regionGroup);
  
          return (
            <div
              key={index}
              className={`region__item ${hoveredGroup === region.regionGroup ? 'hovered-region' : ''}`}
              onMouseEnter={() => setHoveredGroup(region.regionGroup)}
              onMouseLeave={() => setHoveredGroup(null)}
            >
              <div className="region__item__name">{region.name}</div>
              <div className="region__item__count">{region.cr}%</div>
            </div>
          );
        })}
      </div>
    );
};

export default RegionListMap;
  