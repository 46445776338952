import React from "react"
import ContentLoader from "react-content-loader"

const OfferItemSkeleton = (props) => {
	const isFullScreen = window.innerWidth > 1199
	const isMediumScreen = window.innerWidth > 768 && window.innerWidth <= 1199
	const isSmallScreen = window.innerWidth <= 768

	return <ContentLoader
		speed={2}
		viewBox={isFullScreen ? "0 0 1240 178" : isMediumScreen ? "0 0 920 202" : "0 0 350 570"}
		backgroundColor="#979595"
		foregroundColor="#b8b7b7"
		style={{ border: '1px solid #F8FFE4', borderRadius: '20px' }}
		{...props}
	>
		{/* show this skeleton when width is more than 1199 */}
		{isFullScreen && (
			<>
				<rect x="40" y="32" rx="0" ry="0" width="232" height="32" />
				<rect x="40" y="112" rx="20" ry="20" width="161" height="34" />
				<rect x="210" y="112" rx="20" ry="20" width="69" height="34" />
				<rect x="341" y="32" rx="0" ry="0" width="120" height="28" />
				<rect x="341" y="84" rx="12" ry="12" width="258" height="66" />
				<rect x="658" y="34" rx="0" ry="0" width="259" height="106" />
				<rect x="967" y="32" rx="0" ry="0" width="100" height="53" />
				<rect x="1105" y="32" rx="0" ry="0" width="100" height="53" />
				<rect x="1060" y="128" rx="0" ry="0" width="145" height="20" />
			</>
		)}

		{/* show this skeleton when width is more than 768 and less than 1199 */}
		{isMediumScreen && (
			<>
				<rect x="40" y="32" rx="0" ry="0" width="232" height="32" />
				<rect x="40" y="92" rx="20" ry="20" width="161" height="34" />
				<rect x="40" y="136" rx="20" ry="20" width="69" height="34" />
				<rect x="341" y="32" rx="0" ry="0" width="120" height="28" />
				<rect x="341" y="104" rx="12" ry="12" width="258" height="66" />
				<rect x="641" y="32" rx="0" ry="0" width="100" height="53" />
				<rect x="779" y="32" rx="0" ry="0" width="100" height="53" />
				<rect x="734" y="148" rx="0" ry="0" width="145" height="20" />
			</>
		)}

		{/* show this skeleton when width is less than 768 */}
		{isSmallScreen && (
			<>
				<rect x="20" y="32" rx="0" ry="0" width="180" height="32" />
				<rect x="20" y="84" rx="20" ry="20" width="160" height="34" />
				<rect x="190" y="84" rx="20" ry="20" width="70" height="34" />
				<rect x="20" y="135" rx="0" ry="0" width="306" height="1" />
				<rect x="20" y="150" rx="0" ry="0" width="100" height="24" />
				<rect x="20" y="190" rx="15" ry="15" width="306" height="74" />
				<rect x="20" y="280" rx="0" ry="0" width="306" height="1" />
				<rect x="20" y="300" rx="0" ry="0" width="306" height="112" />
				<rect x="20" y="430" rx="0" ry="0" width="236" height="53" />
				<rect x="20" y="500" rx="0" ry="0" width="306" height="1" />
				<rect x="20" y="520" rx="0" ry="0" width="145" height="25" />
			</>
		)}

	</ContentLoader>
}

export default OfferItemSkeleton