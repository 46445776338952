import React from 'react';
import { useSelector } from "react-redux";

// Utils
import { Translate } from "utils/translations/Translate";

// Styles
import '../StatisticsItem/StatisticsItem.scss';

// Images
import instagram from 'assets/images/public/about_geo_landing/instagram.svg';
import twitter from 'assets/images/public/about_geo_landing/twitter.svg';
import facebook from 'assets/images/public/about_geo_landing/facebook.svg';
import pinterest from 'assets/images/public/about_geo_landing/pinterest.svg';


const AboutGeoPolandItem = () => {
    const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('public/poland', lang)
	const t = translation.getWord()

    return (
        <div className={`stats__cards`}>
            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Audience')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_pl`}>
                        <div className={`stats__info_pl_item`} >
                            <div className={`stats__info_pl_title`}>{t('More than 30 million')}</div>
                            <div className={`stats__info_pl_text`}>{t('More than 30 million Internet users, approximately 19 million active users of social networks.')}</div>
                        </div>
                        <div className={`stats__info_pl_item`} >
                            <div className={`stats__info_pl_title`}>{t('More than 98%')}</div>
                            <div className={`stats__info_pl_text`}>{t('More than 98% of search traffic comes from Google. Other search engines - Bing, Yahoo! - only 2% remains.')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`stats__item`}>
            <div className={`stats__heading`}>
                <div className={`stats__title`}>{t('Top social networks')}</div>
                <div className={`stats__lines`}></div>
            </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_traffic`}>
                        <div className={`stats__info_traffic_item`} >
                            <img src={facebook} alt='facebook' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Facebook')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={twitter} alt='twitter' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Twitter')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={pinterest} alt='pinterest' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Pinterest')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={instagram} alt='instagram' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Instagram')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutGeoPolandItem;