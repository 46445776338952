import React from 'react';
import styles from './AdvantagesItem.module.scss'

function AdvantagesItem({title, text, index}) {
    return (
        <li className={styles.advantages__item}>
            <div className={`${styles.advantages__img} img__${index + 1}`} />
            <div className={styles.advantages__info}>
                <h2>{title}</h2>
                <p>{text}</p>
            </div>
        </li>
    );
}

export default AdvantagesItem;