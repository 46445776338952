import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, ChartDataLabels);

const RegionChart = ({ onHover, hoveredIndex, chartData }) => {
  const options = {
    plugins: {
        datalabels: {
            display: true,
            color: '#fff',
            anchor: 'end',
            align: 'top',
            formatter: (value) => `${value}%`,
            font: {
              size: 14,
            },
        },
        tooltip: {
            enabled: false,
        },
        legend: {
            display: false, 
        },
    },
    onHover: (event, elements) => {
        if (elements.length) {
          onHover(elements[0].index);
        } else {
          onHover(null);
        }
      },
      scales: {
        x: {
            grid: {
                display: false,
            },
            ticks: {
                display: false,
            }
        },
        y: {
            grid: {
                display: true, 
                color: 'rgba(255, 255, 255, 0.1)' 
            },
            beginAtZero: true,
            ticks: {
                stepSize: 2, 
                display: true,
                callback: function(value) {
                  return value + '%'; 
                },
                font: {
                  size: 16,
                },
                max: 8,
                min: 0, 
              }
        }
      },
  };

  const datasetWithHover = {
    ...chartData.datasets[0],
    backgroundColor: chartData.labels.map((_, i) =>
      i === hoveredIndex ? 'rgb(128, 255, 0)' : 'rgba(128, 255, 0, 0.1)'
    ),
  };

  const updatedChartData = { ...chartData, datasets: [datasetWithHover] };

  return <Bar data={updatedChartData} options={options} />;
};

export default RegionChart;
