import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const HOST = process.env.REACT_APP_HOST

export const profitOffersApi = createApi({
	reducerPath: 'profitOffersApi',
	baseQuery: fetchBaseQuery({ baseUrl: HOST }),
	endpoints: (build) => ({
		getProfitOffers: build.query({
			query: (lang) => `v1/openstat/offers?lang=${lang}&page=1&perPage=6&onRedirect=0&showOnMain=1`
		})
	})
})

export const { useGetProfitOffersQuery } = profitOffersApi