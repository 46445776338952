import {FooterFactory, HeaderFactory} from "../../components/public";
import RegLogModal from "../../components/public/RegLogModal/RegLogModal";
import {ModalProvider} from "../../utils/customHooks/ModalContext";
import {Outlet, useLocation} from "react-router-dom";
import {useEffect, useRef} from "react";

const MainLayout = ({isAuth, isLandingPage}) => {

    const { pathname,hash } = useLocation();
    const lastHash = useRef('');

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [pathname]);

    useEffect(() => {

        if (hash) {
            lastHash.current = hash.slice(1); // safe hash for further use after navigation
        }

        if (lastHash.current && document.getElementById(lastHash.current)) {
            setTimeout(() => {
                const element = document.getElementById(lastHash.current);
                const position = element.getBoundingClientRect();
                document.documentElement.scrollTo({
                    top:  position.top + document.documentElement.scrollTop - 120,
                    left: position.left,
                    behavior: "smooth", // Optional if you want to skip the scrolling animation
                });
                lastHash.current = '';
            }, 100);
        }
    }, [hash]);

    return <>
        <ModalProvider>
            <HeaderFactory isAuth={isAuth} isLandingPage={isLandingPage}/>
            <Outlet/>
            <FooterFactory isAuth={isAuth} isLandingPage={isLandingPage}/>
            {/* If user is auth don't show login modal */}
            {!isAuth && <RegLogModal/>}
        </ModalProvider>
    </>

}

export default MainLayout;