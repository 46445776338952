import React from 'react';
import { useSelector } from "react-redux";

// Utils
import { Translate } from "utils/translations/Translate";

// Styles
import '../StatisticsItem/StatisticsItem.scss';

// Images
import facebook from 'assets/images/public/about_geo_landing/facebook.svg';
import google from 'assets/images/public/about_geo_landing/google.svg';
import youtube from 'assets/images/public/about_geo_landing/youtube.svg';


const AboutGeoSriLankaItem = () => {
    const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('public/sriLanka', lang)
	const t = translation.getWord()

    return (
        <div className={`stats__cards`}>
            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Audience')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_lk`}>
                        <div className={`stats__info_lk_item`} >
                            <div className={`stats__info_lk_title`}>{t('66%')}</div>
                            <div className={`stats__info_lk_text`}>{t('66% of the population (about 14.68 million) are Internet users.')}</div>
                        </div>
                        <div className={`stats__info_lk_item`} >
                            <div className={`stats__info_lk_title`}>{t('80%')}</div>
                            <div className={`stats__info_lk_text`}>{t(`80% of users access the network from mobile devices, of which the share of Apple devices is only 7%.`)}</div>
                        </div>
                        <div className={`stats__info_lk_item`} >
                            <div className={`stats__info_lk_title`}>{t('186$')}</div>
                            <div className={`stats__info_lk_text`}>{t('$186 average salary in the country.')}</div>
                        </div>
                        <div className={`stats__info_lk_item`} >
                            <div className={`stats__info_lk_title`}>{t('2')}</div>
                            <div className={`stats__info_lk_text`}>{t('2 official languages: Sinhala and Tamil. 31% of residents speak English.')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Top social media')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_traffic`}>
                        <div className={`stats__info_traffic_item`} >
                            <img src={facebook} alt='facebook' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Facebook')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={youtube} alt='youtube' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Youtube')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={google} alt='google' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Google')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutGeoSriLankaItem;