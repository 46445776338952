import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const HOST = process.env.REACT_APP_HOST

export const profitVietnamOffersApi = createApi({
	reducerPath: 'profitVietnamOffersApi',
	baseQuery: fetchBaseQuery({ baseUrl: HOST }),
	endpoints: (build) => ({
		getProfitVietnamOffers: build.query({
			query: (lang) => `v1/openstat/offers?lang=${lang}&region=VN&page=1&perPage=30&onRedirect=0&showOnMain=1`
		})
	})
})

export const { useGetProfitVietnamOffersQuery } = profitVietnamOffersApi