import axios from '../utils/axios'

const AUTH_HOST = process.env.REACT_APP_AUTH_HOST
// const SERVICE_HOST = process.env.REACT_APP_SERVICE_HOST

export const authApi = {
	getThrottlingLevel() {
		return axios(AUTH_HOST).get('v1/me/throttling-level')
	},
	registrationWeb(data) {
		return axios(AUTH_HOST, "multipart/form-data").post('v1/registration', data)
	},
	registrationOffer(data) {
		return axios(AUTH_HOST, "multipart/form-data").post('v1/registration/offer', data)
	},
	login(data) {
		return axios(AUTH_HOST).post('v1/auth', data)
	},
	restorePassword(data) {
		return axios(AUTH_HOST, "multipart/form-data").post('v1/restore-password', data)
	}
}
