import person1 from '../../../assets/images/public/reviews/person1.jpg'
import person2 from '../../../assets/images/public/reviews/person2.jpg'
import person3 from '../../../assets/images/public/reviews/person3.jpg'
import person4 from '../../../assets/images/public/reviews/person4.jpg'
import person5 from '../../../assets/images/public/reviews/person5.jpg'
import person6 from '../../../assets/images/public/reviews/person6.jpg'
import person7 from '../../../assets/images/public/reviews/person7.jpg'
import {Translate} from "../../../utils/translations/Translate";

const getReviewsLandingData = (lang) => {

    const translation = new Translate('public/reviews', lang)
    const t = translation.getWord()

    return [
        {
            name: t('Sergey Lunev'),
            photo: person1,
            link: '',
            position: t('Co-founder, CTO ReadyTop (publisher)'),
            shortText: t(`I, being a tech guy, have several main reasons for working with PDL-Profit - high payrates, timely payouts and a reliable technical system. I haven't encountered a single technical problem during all this time, the statistics are accurate and there's plenty of cool tools to test out almost any marketing ideas. Smartlink, showcase builder, convenient postback generator - everything works perfectly.`)
        },
        {
            name: t('Vladimir Donskov'),
            photo: person2,
            link: 'https://www.facebook.com/vladimir.donskov',
            position: t('Deputy CMO, Miloan.ua (advertiser)'),
            shortText: t('За что мы ценим PDL-profit? Большие объемы качественного трафика — этого аргумента уже было бы достаточно. Но есть ещё и другие отличительные особенности, которые нельзя не отметить :\n\n- Привлечение сильных новых вебмастеров на украинский PDL-рынок из других ниш/рынков .\n- Профессиональная, оперативная и откровенная коммуникация по всем рабочим вопросам .\n- Внимание к качеству трафика — проактивный мониторинг вебов со стороны сети .\n- Постоянное развитие и адаптивность — на платформе появляется всё новый и новый функционал, делающий работу с ней всё более удобной и эффективной .\n- Генерация новых идей по улучшению работы для всех участников рынка.\nЖелаем команде PDLP продолжать в том же духе и дальше способствовать развитию ниши .')
        },
        {
            name: t('Dmitry Petrenko'),
            photo: person3,
            link: 'https://www.facebook.com/PetrenkoKievSEO',
            position: t('(publisher)'),
            shortText: t(`When I started working with this affiliate program, I had my share of doubts - everything seemed way to good to be true. And practice shows, that's rarely the case. But I was relieved to find out that my being cautious was all unjustified: lots of offers, high payrates and the best part - quick payouts on demand. The support team knows what it's doing and helps solve regularly arising problems with advertisers. Everything an AP needs have is present.`)
        }, {
            name: t('Anastasia Chernyshova'),
            photo: person4,
            link: '',
            position: t('CMO Money4you (advertiser)'),
            shortText: t(`From the very first day of cooperating with the PDL-Profit CPA network, we've been getting high-quality traffic at an acceptable cost for us. I'd also like to draw attention to how simple the advertiser's account interface is, the publisher's checklists, which practically exclude fraud, as well as transparent reports and statistics on the Money4you offer. Special thanks to Alexander for his advice! We're going to keep working with this network and I'm sure that there's plenty of amazing projects along the road.`)
        },
        {
            name: t('Ivan Tikhonenko'),
            photo: person5,
            link: 'https://www.facebook.com/1tykhonenko.ivan/',
            position: t('(publisher)'),
            shortText: t(`I've known these guys for a while now (I worked with them on other projects). I started cooperating with PDL-Profit from the very beginning. I was already engaged in the financial vertical when I heard that a new affiliate program was starting out. My crew and I decided to join up with them before they even officially launched. The support team's responsive and there's no hold for payouts. And if any complicated situation arises, you can be sure they'll help you out.`)
        },
        {
            name: t('Makedonsky Alexander'),
            photo: person6,
            link: 'https://www.facebook.com/makedonskiy.a1',
            position: t('Affiliate manager CCloan (advertiser)'),
            shortText: t(`We have been cooperating with PDLP for more than six months and the result was not long in coming. From the very first days, we noticed decent traffic volumes and the network's potential. We would like to note that all the process are automatic and all the necessary tools provided both in the publisher and advertiser accounts. We've had many little bumps along the way, and giving credit where credit is due - the support team handled all of them flawlessly. Comparing data and issuing payments takes place in a timely manner. As for the cons, there's not really much to say here. Any disagreements we had were promptly settled.`)
        },
        {
            name: t('Maanimo team'),
            photo: person7,
            link: '',
            position: t('(publishers)'),
            shortText: t(`Our partnership began back when we were testing the PDL-profit platform. These guys were very quick to respond to all the comments and suggestions regarding all the features. Having quite a lot of experience working with various CPA affiliates, we can confidently say that the interface and functionality of PDL-profit are among the best we've ever seen - everything is easy to understand and use. Ever since we started working with them, we've yet to detect any sort of shaving or non-compliance with partnership obligations.`)
        },

    ];

}

export default getReviewsLandingData;