import React from 'react';
import { useSelector } from "react-redux";

// Utils
import { Translate } from "utils/translations/Translate";

// Styles
import '../StatisticsItem/StatisticsItem.scss';

// Images
import instagram from 'assets/images/public/about_geo_landing/instagram.svg';
import whatsapp from 'assets/images/public/about_geo_landing/whatsapp.svg';
import facebook from 'assets/images/public/about_geo_landing/facebook.svg';
import tiktok from 'assets/images/public/about_geo_landing/tiktok.svg';
import twitter from 'assets/images/public/about_geo_landing/twitter.svg';


const AboutGeoSouthAfricaItem = () => {
    const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('public/southAfrica', lang)
	const t = translation.getWord()

    return (
        <div className={`stats__cards`}>
            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Audience')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_za`}>
                        <div className={`stats__info_za_item`} >
                            <div className={`stats__info_za_title`}>{t('60 million')}</div>
                            <div className={`stats__info_za_text`}>{t('60 million people population, 70% of whom use the Internet')}</div>
                        </div>
                        <div className={`stats__info_za_item`} >
                            <div className={`stats__info_za_title`}>{t('80%')}</div>
                            <div className={`stats__info_za_text`}>{t(`80% of the population has microloans, while only 11% of loans are overdue`)}</div>
                        </div>
                        <div className={`stats__info_za_item`} >
                            <div className={`stats__info_za_title`}>{t('$1 440')}</div>
                            <div className={`stats__info_za_text`}>{t('$1 440 average salary in the country, average client age - 34 years, average loan - $75')}</div>
                        </div>
                        <div className={`stats__info_za_item`} >
                            <div className={`stats__info_za_title`}>{t('15')}</div>
                            <div className={`stats__info_za_text`}>{t('15 official languages, most residents understand English well, but in everyday life many speak Zulu and Xhosa')}</div>
                        </div>
                        <div className={`stats__info_za_item`} >
                            <div className={`stats__info_za_title`}>{t('28 million')}</div>
                            <div className={`stats__info_za_text`}>{t('More than 28 million accounts on social networks.')}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Top social media')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_traffic`}>
                        <div className={`stats__info_traffic_item`} >
                            <img src={whatsapp} alt='whatsapp' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('WhatsApp')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={facebook} alt='facebook' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Facebook')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={instagram} alt='instagram' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Instagram')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={twitter} alt='twitter' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Twitter (X)')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={tiktok} alt='tiktok' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Tiktok')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutGeoSouthAfricaItem;