import person1 from '../../../assets/images/public/reviews/person1.jpg'
import person2 from '../../../assets/images/public/reviews/person2.jpg'
import person3 from '../../../assets/images/public/reviews/person3.jpg'
import person4 from '../../../assets/images/public/reviews/person4.jpg'
import person5 from '../../../assets/images/public/reviews/person5.jpg'
import person6 from '../../../assets/images/public/reviews/person6.jpg'
import person7 from '../../../assets/images/public/reviews/person7.jpg'
import {Translate} from "../../../utils/translations/Translate";

const reviewsData = (lang = 'en') => {

    const translation = new Translate('public/reviews', lang)
    const t = translation.getWord()

    return [
        {
            name: t('Сергей Лунев'),
            photo: person1,
            link: 'https://www.facebook.com/profile.php?id=100003183574896',
            position: t('Co-founder, CTO ReadyTop [вебмастер]'),
            shortText: t('Для меня, как для технаря, есть несколько основных причин для сотрудничества с PDL Profit - высокие ставки, своевременные выплаты и надежная техническая система. Ни одного сбоя за всё время работы, адекватная, правдивая статистика и масса удобных инструментов для тестирования практически любых маркетинговых идей. Смартлинк, конструктор витрин, удобный генератор постбэков - всё работает идеально.'),
            allText: t('Для меня, как для технаря, есть несколько основных причин для сотрудничества с PDL Profit - высокие ставки, своевременные выплаты и надежная техническая система. Ни одного сбоя за всё время работы, адекватная, правдивая статистика и масса удобных инструментов для тестирования практически любых маркетинговых идей. Смартлинк, конструктор витрин, удобный генератор постбэков - всё работает идеально.')
        },
        {
            name: t('Владимир Донсков'),
            photo: person2,
            link: 'https://www.facebook.com/vladimir.donskov',
            position: t('Deputy CMO, Miloan.ua [рекламодатель]'),
            shortText: t('За что мы ценим PDL-profit? Большие объемы качественного трафика — этого аргумента уже было бы достаточно. Но есть ещё и другие отличительные особенности, которые нельзя не отметить :\n\n- Привлечение сильных новых вебмастеров на украинский PDL-рынок из других ниш/рынков .\n- Профессиональная, оперативная и откровенная коммуникация по всем рабочим вопросам .\n- Внимание к качеству трафика — проактивный мониторинг вебов со стороны сети .\n- Постоянное развитие и адаптивность — на платформе появляется всё новый и новый функционал, делающий работу с ней всё более удобной и эффективной .\n- Генерация новых идей по улучшению работы для всех участников рынка.\nЖелаем команде PDLP продолжать в том же духе и дальше способствовать развитию ниши .'),
            allText: t('За что мы ценим PDL-profit? Большие объемы качественного трафика — этого аргумента уже было бы достаточно. Но есть ещё и другие отличительные особенности, которые нельзя не отметить :\n\n- Привлечение сильных новых вебмастеров на украинский PDL-рынок из других ниш/рынков .\n- Профессиональная, оперативная и откровенная коммуникация по всем рабочим вопросам .\n- Внимание к качеству трафика — проактивный мониторинг вебов со стороны сети .\n- Постоянное развитие и адаптивность — на платформе появляется всё новый и новый функционал, делающий работу с ней всё более удобной и эффективной .\n- Генерация новых идей по улучшению работы для всех участников рынка.\nЖелаем команде PDLP продолжать в том же духе и дальше способствовать развитию ниши .')
        },
        {
            name: t('Дмитрий Петренко'),
            photo: person3,
            link: 'https://www.facebook.com/PetrenkoKievSEO',
            position: t('[вебмастер]'),
            shortText: t('В начале работы партнёрки у меня было много опасений, так как слишком гладко и слишком сладко было все написано. Как показывает практика - такого не бывает 😅. Но в итоге я рад, что зря осторожничал: много офферов, высокие ставки и самое главное очень быстрые выплаты по запросу. Адекватный саппорт и решение регулярно возникающих вопросов с рекламодателями, когда льешь объемы. Все что должно быть в ПП - тут есть.'),
            allText: t('В начале работы партнёрки у меня было много опасений, так как слишком гладко и слишком сладко было все написано. Как показывает практика - такого не бывает 😅. Но в итоге я рад, что зря осторожничал: много офферов, высокие ставки и самое главное очень быстрые выплаты по запросу. Адекватный саппорт и решение регулярно возникающих вопросов с рекламодателями, когда льешь объемы. Все что должно быть в ПП - тут есть.')
        }, {
            name: t('Анастасия Чернышова'),
            photo: person4,
            link: 'https://www.facebook.com/anastasiia.chernyshova',
            position: t('CMO Money4You [рекламодатель]'),
            shortText: t('С самого первого дня сотрудничества с международной CPA-сетью PDL-Profit мы получаем качественный трафик по приемлемой для нас стоимости. Отмечу понятный интерфейс личного кабинета рекламодателя, чек-листы для веб-мастеров, которые практически исключают фрод, а также прозрачную отчетность и статистику по офферу Money4You. Отдельная благодарность Александру за советы! Мы продолжаем успешное сотрудничество и я уверена, что новые перспективные проекты у нас еще впереди!'),
            allText: t('С самого первого дня сотрудничества с международной CPA-сетью PDL-Profit мы получаем качественный трафик по приемлемой для нас стоимости. Отмечу понятный интерфейс личного кабинета рекламодателя, чек-листы для веб-мастеров, которые практически исключают фрод, а также прозрачную отчетность и статистику по офферу Money4You. Отдельная благодарность Александру за советы! Мы продолжаем успешное сотрудничество и я уверена, что новые перспективные проекты у нас еще впереди!')
        },
        {
            name: t('Иван Тихоненко'),
            photo: person5,
            link: 'https://www.facebook.com/profile.php?id=100001580591221',
            position: t('[вебмастер]'),
            shortText: t('Знаю ребят уже очень давно по их другим проектам. Начал сотрудничать с PDL-Profit c первого дня их работы. Мы уже работали в вертикали финансов и когда узнали что открывается новая пп начали работать с ними до официального открытия. Всегда отзывчивый саппорт и выплаты без холда. Помогают в любой ситуации если такие возникают.'),
            allText: t('Знаю ребят уже очень давно по их другим проектам. Начал сотрудничать с PDL-Profit c первого дня их работы. Мы уже работали в вертикали финансов и когда узнали что открывается новая пп начали работать с ними до официального открытия. Всегда отзывчивый саппорт и выплаты без холда. Помогают в любой ситуации если такие возникают.')
        },
        {
            name: t('Македонский Александр'),
            photo: person6,
            link: 'https://www.facebook.com/makedonskiy.a1',
            position: t('Affiliate manager CCloan [рекламодатель]'),
            shortText: t('Сотрудничаем с PDLP уже более полугода и результат не заставил себя долго ожидать. Уже с первых дней интеграции заметили хорошие объемы трафика и потенциал сети. Хотим отметить, что работа сети отлично автоматизирована и все необходимые инструменты доступны в личном кабинете как для вебмастера, так и для рекламодателя. В ходе сотрудничества возникало много вопросов и здесь важно сказать, что команда оперативно отвечает и относится очень тщательно и ответственно, оперативно проходит процесс сверок и выставления счетов. Что касается минусов, то как таковых выделить не можем - все разногласия мы аргументировали и приходили к общему знаменателю.'),
            allText: t('Сотрудничаем с PDLP уже более полугода и результат не заставил себя долго ожидать. Уже с первых дней интеграции заметили хорошие объемы трафика и потенциал сети. Хотим отметить, что работа сети отлично автоматизирована и все необходимые инструменты доступны в личном кабинете как для вебмастера, так и для рекламодателя. В ходе сотрудничества возникало много вопросов и здесь важно сказать, что команда оперативно отвечает и относится очень тщательно и ответственно, оперативно проходит процесс сверок и выставления счетов. Что касается минусов, то как таковых выделить не можем - все разногласия мы аргументировали и приходили к общему знаменателю.')
        },
        {
            name: t('Maanimo'),
            photo: person7,
            link: '#',
            position: t('[вебмастер]'),
            shortText: t('Наши партнёрские отношения начались ещё на этапе тестирования платформы PDL-profit. Ребята очень быстро реагировали на замечания и пожелания относительно функционала. Имея достаточно большой опыт работы с различными CPA-партнёрками, с уверенностью можем сказать, что интерфейс и функционал PDL-profit являются одними из наиболее удобных и понятных. За все время работы не было замечено шейва или несоблюдения партнёрских обязательств. Ждем sempro и кристально честного розыгрыша Audi Q8)). Различные формы выплат также являются ещё одним из преимуществ. Из пожеланий: большее количество офферов под другие ГЕО, кроме Украины и России, а также эксклюзивных офферов под Украину.'),
            allText: t('Наши партнёрские отношения начались ещё на этапе тестирования платформы PDL-profit. Ребята очень быстро реагировали на замечания и пожелания относительно функционала. Имея достаточно большой опыт работы с различными CPA-партнёрками, с уверенностью можем сказать, что интерфейс и функционал PDL-profit являются одними из наиболее удобных и понятных. За все время работы не было замечено шейва или несоблюдения партнёрских обязательств. Ждем sempro и кристально честного розыгрыша Audi Q8)). Различные формы выплат также являются ещё одним из преимуществ. Из пожеланий: большее количество офферов под другие ГЕО, кроме Украины и России, а также эксклюзивных офферов под Украину.')
        },

    ]
}

export default reviewsData