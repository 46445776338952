import React from 'react';
import { HeaderPublic, HeaderLanding } from "../index";
// import { Button } from 'components/general';
// import { useDispatch } from "react-redux";
// import { removeRefresh, removeToken } from "redux/slices/auth/authSlice";

const HeaderFactory = ({ isAuth, isLandingPage }) => {
  // const dispatch = useDispatch()

  // const logOutHandler = () => {
  //   dispatch(removeRefresh())
  //   dispatch(removeToken())
  // };
	
  return isAuth ? <HeaderPublic /> : isLandingPage ? <HeaderLanding /> : <HeaderPublic />;

};

export default HeaderFactory;