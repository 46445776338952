import React from 'react';
import Button from "components/general/Button/Button";

const SliderBlock = ({img, midHeader, reg, text, topHeader, fz, showRegLogModal}) => {
    return (
        <div className='main__slider--block d-flex justify-content-between'>
            <div className='main__slider--img'>
                <img src={img} alt="img" />
            </div>
            <div className='main__slider--info'>
                <h1 className={fz && 'reduce'}>{topHeader}</h1>
                <h2>{midHeader}</h2>
                <p>{text}</p>
                <Button className='outline-green' onClick={() => showRegLogModal('registration')}>{reg}</Button>
            </div>
        </div>
    );
}

export default SliderBlock;