import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Translate } from "utils/translations/Translate";

// Components
import {
    RegionChart,
    RegionMap,
    RegionListMap,
    RegionListChart
} from "components/public";

//Style
import '../StatisticsItem.scss'

import getRegionData from './regionsData';

const StatisticsMexicoItem = ({ data }) => {

  const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('public/mexico', lang)
	const t = translation.getWord()

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredGroup, setHoveredGroup] = useState(null);

  const regionsData = getRegionData(lang);


  const dataRegionChart = [
      { region: t('Baja California'), cr: 2.56 },
      { region: t('Northern Mexico'), cr: 4.59 },
      { region: t('The Bajio'), cr: 2.32 },
      { region: t('Central Mexico'), cr: 6.37 },
      { region: t('Pacific Coast'), cr: 2.47 },
      { region: t('Yucatan'), cr: 4.36 },
    ];

  const chartData = {
    labels: dataRegionChart.map(d => d.cr),
    datasets: [
      {
        data: dataRegionChart.map(d => d.cr),
        backgroundColor: 'rgba(128, 255, 0, 0.1)',
        datalabels: {
          color: '#F8FFE4', 
          anchor: 'end',
          align: 'top',
          formatter: function(value) {
            return value + '%';
          }
        }
      }
    ],
  };

 return (
    <div className={`stats__cards`}>
      {data.data.map((stat, i) => (
        <div className={`stats__item`} key={i}>
          <div className={`stats__heading`}>
              <div className={`stats__title`}>{stat.title}</div>
              <div className={`stats__lines`}></div>
          </div>
          <div className={`stats__content`}>
            <div className={`stats__img`}>
              <img src={stat.img} alt={`Diagram for ${stat.title}`} />
            </div>
            <div className={`stats__info`}>
              {stat.stats.map((item, index) => (
                <div className={`stats__info_item`} key={index}>
                 <div className={`stats__info_name`}>{item.name}</div>
                 <div className={`stats__info_count`}>{item.value}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
      <div className={`stats__item`}>
          <div className={`stats__heading`}>
              <div className={`stats__title`}>{t('Coverage by regions')}</div>
              <div className={`stats__lines`}></div>
          </div>
          <div className={`stats__content`}>
            <div className={`region__map`}>
              <RegionMap
                regionsData={regionsData}
                hoveredGroup={hoveredGroup}
                setHoveredGroup={setHoveredGroup}
              />
            </div>
          <RegionListMap
            regionsData={regionsData}
            hoveredGroup={hoveredGroup}
            setHoveredGroup={setHoveredGroup}
          />
          </div>
        </div> 
      <div className={`stats__item`}>
          <div className={`stats__heading`}>
              <div className={`stats__title`}>{t('Conversion Rate by regions')}</div>
              <div className={`stats__lines`}></div>
          </div>
          <div className={`stats__content`}>
            <div className={`chart`}>
              <div className={`chart-container`}>
                <RegionChart
                  onHover={setHoveredIndex}
                  chartData={chartData}
                  hoveredIndex={hoveredIndex}
                />
              </div>
            </div>


            <RegionListChart
                onHover={setHoveredIndex}
                data={dataRegionChart}
                hoveredIndex={hoveredIndex}
            />
          </div>
      </div>                
    </div>
 );
};

export default StatisticsMexicoItem;