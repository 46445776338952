import React from 'react';
import Modal from "react-bootstrap/Modal";
import styles from './RegLogModal.module.scss'
import Registration from "./Registration";
import Login from "./Login";
import BlockedAccount from "./BlockedAccount";
import ForgotPassword from "./ForgotPassword";
import { useModalContext } from "utils/customHooks/ModalContext";

const RegLogModal = () => {

	const { modalType, showRegLogModal, show, closeRegLogModal } = useModalContext();

	return (
		<Modal size='xl' className={styles.modal} dialogClassName={modalType} centered show={show} onHide={() => closeRegLogModal()} data-t={modalType}>
			<span onClick={() => closeRegLogModal()} className='closeBtn'>
                <svg xmlns="http://www.w3.org/2000/svg" height="512px" version="1.1" viewBox="0 0 512 512"
					 width="512px"><path
					d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z" /></svg>
            </span>

			{modalType === 'registration' && <Registration showRegLogModal={showRegLogModal} />}
			{modalType === 'login' && <Login showRegLogModal={showRegLogModal} />}
			{modalType === 'forgotPassword' && <ForgotPassword showRegLogModal={showRegLogModal}/>}
			{modalType === 'blockedAccount' && <BlockedAccount />}
		</Modal>

	)
};

export default RegLogModal;