import React from 'react';
import { useSelector } from "react-redux";

// Utils
import { Translate } from "utils/translations/Translate";

// Styles
import '../StatisticsItem/StatisticsItem.scss';

// Images
import ellipse from 'assets/images/general/ellipse.svg'

import instagram from 'assets/images/public/about_geo_landing/instagram.svg';
import google from 'assets/images/public/about_geo_landing/google.svg';
import facebook from 'assets/images/public/about_geo_landing/facebook.svg';
import youtube from 'assets/images/public/about_geo_landing/youtube.svg';
import yahoo from 'assets/images/public/about_geo_landing/yahoo.svg';
import reddit from 'assets/images/public/about_geo_landing/reddit.svg';
import telegram from 'assets/images/public/about_geo_landing/telegram.svg';

const AboutGeoIndiaItem = () => {
    const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('public/india', lang)
	const t = translation.getWord()

    return (
        <div className={`stats__cards`}>
            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Audience')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_in`}>
                        <div className={`stats__info_in_item`} >
                            <div className={`stats__info_in_title`}>{t('1 billion')}</div>
                            <div className={`stats__info_in_text`}>{t('1 billion solvent population')}</div>
                        </div>
                        <div className={`stats__info_in_item`} >
                            <div className={`stats__info_in_title`}>{t('446 million')}</div>
                            <div className={`stats__info_in_text`}>{t('446 million active internet users')}</div>
                        </div>
                        <div className={`stats__info_in_item`} >
                            <div className={`stats__info_in_title`}>{t('1st place')}</div>
                            <div className={`stats__info_in_text`}>{t('1st place in terms of number of Facebook users - about 350 million')}</div>
                        </div>
                        <div className={`stats__info_in_item`} >
                            <div className={`stats__info_in_title`}>{t('24 years')}</div>
                            <div className={`stats__info_in_text`}>{t('24 years is the average age in the country. Teenagers and children under 18 make up more than half of the population')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Finances are concentrated in big cities')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_city`}>
                        <div className={`stats__info_city_item`} >
                            <img src={ellipse} alt='new-delhi' className={`stats__info_city_img`}/>
                            <div className={`stats__info_city_text`}>{t('New Delhi')}</div>
                        </div>
                        <div className={`stats__info_city_item`} >
                            <img src={ellipse} alt='mumbai' className={`stats__info_city_img`}/>
                            <div className={`stats__info_city_text`}>{t('Mumbai')}</div>
                        </div>
                        <div className={`stats__info_city_item`} >
                            <img src={ellipse} alt='bangalore' className={`stats__info_city_img`}/>
                            <div className={`stats__info_city_text`}>{t('Bangalore')}</div>
                        </div>
                        <div className={`stats__info_city_item`} >
                            <img src={ellipse} alt='chennai' className={`stats__info_city_img`}/>
                            <div className={`stats__info_city_text`}>{t('Chennai')}</div>
                        </div>
                        <div className={`stats__info_city_item`} >
                            <img src={ellipse} alt='pune' className={`stats__info_city_img`}/>
                            <div className={`stats__info_city_text`}>{t('Pune')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`stats__item`}>
                <div className={`stats__heading`}>
                    <div className={`stats__title`}>{t('Top social networks')}</div>
                    <div className={`stats__lines`}></div>
                </div>
                <div className={`stats__content`}>
                    <div className={`stats__info_traffic`}>
                        <div className={`stats__info_traffic_item`} >
                            <img src={google} alt='google' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Google')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={facebook} alt='facebook' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Facebook')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={instagram} alt='instagram' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Instagram')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={youtube} alt='youtube' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Youtube')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={yahoo} alt='yahoo' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Yahoo')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={reddit} alt='reddit' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Reddit')}</div>
                        </div>
                        <div className={`stats__info_traffic_item`} >
                            <img src={telegram} alt='telegram' className={`stats__info_traffic_img`}/>
                            <div className={`stats__info_traffic_text`}>{t('Telegram')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutGeoIndiaItem;