import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    parameters: {}
}

const loginParamsSlice = createSlice({
    name: 'loginParams',
    initialState,
    reducers: {
        setTgAuth(state, {payload}) {
            state.parameters.tgauth = payload;
        },
        clearParams(state){
            state.parameters = {}
        }
    }
});
export const {
    setTgAuth,
    clearParams,
} = loginParamsSlice.actions
export default loginParamsSlice.reducer;

